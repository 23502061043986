import { EDITOR_TOOLBARS } from 'utils/enums';

export const EDITOR_TOOLBARS_INIT = [
  EDITOR_TOOLBARS.UNDO_REDO,
  EDITOR_TOOLBARS.FORMAT_SELECT,
  EDITOR_TOOLBARS.FONT_FAMILY,
  EDITOR_TOOLBARS.FONT_SIZE,
  EDITOR_TOOLBARS.TEXT_FORMAT,
  EDITOR_TOOLBARS.ALIGNMENT,
  EDITOR_TOOLBARS.LISTS,
  EDITOR_TOOLBARS.OUTDENT_INDENT,
  EDITOR_TOOLBARS.REMOVE_FORMAT,
  // EDITOR_TOOLBARS.HELP,
  EDITOR_TOOLBARS.LINK,
  EDITOR_TOOLBARS.IMAGE,
  EDITOR_TOOLBARS.TABLE,
  EDITOR_TOOLBARS.PREVIEW,
  EDITOR_TOOLBARS.INSERT_DATETIME,
  EDITOR_TOOLBARS.TEXT_COLOR,
  EDITOR_TOOLBARS.MEDIA,
  EDITOR_TOOLBARS.SEARCH_REPLACE,
  EDITOR_TOOLBARS.TOC,
  EDITOR_TOOLBARS.VISUAL_BLOCKS,
  EDITOR_TOOLBARS.VISUAL_CHARS,
  EDITOR_TOOLBARS.CODE_SAMPLE,
  // EDITOR_TOOLBARS.FULLSCREEN,
  EDITOR_TOOLBARS.QUICKBARS,
  EDITOR_TOOLBARS.AUTORESIZE
];
