import { usePaging } from "hooks/usePaging";
import { useGetCustomerQuery } from "services";
import { FindAllCustomerDto } from "types";
import { DEFAULT_PAGE_SIZE } from "utils";

const pageSize = 7;
export function useCustomerPaging(params: Omit<FindAllCustomerDto, 'pageIndex' | 'pageSize'>) {
    const {
        customerName,
        customerAddress,
        meterSerialNumber,
        customerCode,
        routeCode,
        meterPointCode,
        organizationUnitCode,
        wardId,
        districtId,
    } = params;
    const {pageIndex, changePage, resetPage} = usePaging(params);
    const {
        data: customersResponse,
        error,
        isFetching,
        isLoading
    } = useGetCustomerQuery(
        {
            pageIndex: pageIndex,
            pageSize,
            customerName,
            customerAddress,
            meterSerialNumber,
            customerCode,
            routeCode,
            meterPointCode,
            organizationUnitCode,
            wardId,
            districtId,
        },
    );
    const handleChangePage = changePage;
    
    return {
        customers: customersResponse?.data.rows || [],
        count: customersResponse ? customersResponse.data.count : 0,
        isLoading: isFetching || isLoading,
        pageIndex,
        handleChangePage,
        resetPage,
        error
    }
}