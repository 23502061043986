import { Skeleton, Typography } from 'antd';
import { Avatar, InfiniteScroll, List } from 'components/common';
import { usePermissionsUsers } from 'hooks';

type PermissionUserListProps = {
  permissionId: number;
};

const PermissionUserList = ({ permissionId }: PermissionUserListProps) => {
  const { hasMore, handleLoadMore, isLoading, users } = usePermissionsUsers({
    permissionId
  });

  return (
    <InfiniteScroll
      hasMore={hasMore}
      next={handleLoadMore}
      isLoading={isLoading}
      loader={<Skeleton active title={false} paragraph={{ rows: 3 }} />}
    >
      <List
        dataSource={users}
        rowKey={(item) => item.userId}
        renderItem={(item) => (
          <List.Item className='justify-start gap-x-2 border-none p-0 pb-4'>
            <Avatar size={40} fileId={item.avatar} name={item.fullName} />
            <Typography.Text>{item.fullName}</Typography.Text>
          </List.Item>
        )}
      />
    </InfiniteScroll>
  );
};

export default PermissionUserList;
