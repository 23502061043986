import { Button, Spin, Typography } from 'antd';
import { ArrowLeftIcon } from 'assets';
import { joinChatSession, leaveChatSession, useCompleteChatSessionMutation } from 'services';
import { useEffect } from 'react';
import { useOmniChatContext } from 'contexts';
import OmniChatInput from './OmniChatInput';
import { message } from 'components/common';
import OmniChatMessageBox from './OmniChatMessageBox';
import { omniChatMessages } from 'messages';

type OmniChatSessionProps = {
  chatId: number;
  chatSessionId: number;
  onClose?: () => void;
  unreadCount?: number;
};

const OmniChatSession: React.FC<OmniChatSessionProps> = ({ chatId, chatSessionId, onClose, unreadCount }) => {
  const { chatSession } = useOmniChatContext();

  const [completeChatSession, { isLoading: isLoadingComplete }] = useCompleteChatSessionMutation();

  useEffect(() => {
    if (chatSessionId) {
      joinChatSession({ chatSessionId });
    }
    return () => {
      leaveChatSession({ chatSessionId });
    };
  }, [chatSessionId]);

  const goBack = () => {
    onClose?.();
  };

  const handleCompleteChatSession = () => {
    completeChatSession({
      chatSessionId
    })
      .unwrap()
      .then((rs) => {
        goBack();
        message.systemSuccess(rs.message);
      });
  };

  return (
    <div className='flex h-full flex-col'>
      <div className='relative flex h-14 items-center border-b bg-white py-3 pl-3 pr-5'>
        <Button onClick={goBack} type='text' className='h-8 w-8' shape='circle' icon={<ArrowLeftIcon />}></Button>

        {unreadCount && unreadCount > 0 ? (
          <div className='flex h-fit min-h-[18px] min-w-[18px] items-center justify-center rounded-full bg-colorBgError px-[6px]'>
            <Typography.Text className='text-xs font-semibold text-white'>{unreadCount}</Typography.Text>
          </div>
        ) : null}

        <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
          <div className='flex flex-col items-center justify-center'>
            <Typography.Title className='mb-1 text-sm' level={5}>
              {chatSession.chat.customerName}
            </Typography.Title>

            <Typography.Text type='secondary' className='text-sm'>
              {chatSession.chat.pageName}
            </Typography.Text>
          </div>
        </div>

        <div className='ml-auto'>
          <Button
            onClick={handleCompleteChatSession}
            loading={isLoadingComplete}
            size='small'
            className='h-8 w-[88px] rounded-base text-sm'
            danger
          >
            {omniChatMessages.complete}
          </Button>
        </div>
      </div>

      {chatSession ? (
        <div className='flex h-[calc(100%-52px)] flex-col'>
          <OmniChatMessageBox />
          <OmniChatInput />
        </div>
      ) : (
        <Spin spinning />
      )}
    </div>
  );
};

export default OmniChatSession;
