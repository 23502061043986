import { Descriptions, Divider, DrawerProps, Typography } from 'antd';
import { CommonStatus, Drawer } from 'components/common';
import { useEffect } from 'react';
import { useGetApiSharingPartnerDetailQuery } from 'services';
import { twMerge } from 'tailwind-merge';
import ApiSharingPartnerActions from './ApiSharingPartnerActions';
import { apiSharingPartnersMessages, messages } from 'messages';
import { ApiSharingPartnerDto } from 'types';

type ApiSharingPartnerDrawerProps = DrawerProps & {
  apiSharingPartnerId?: number;
  onChangeLoading?: (value: boolean) => void;
  onUpdateStatus?: (apiSharingPartner: ApiSharingPartnerDto) => void;
  onUpdateInfo?: (apiSharingPartner: ApiSharingPartnerDto) => void;
  onDelete?: (apiSharingPartner: ApiSharingPartnerDto) => void;
};

const ApiSharingPartnerDrawer = ({
  apiSharingPartnerId,
  onChangeLoading,
  onUpdateStatus,
  onUpdateInfo,
  onDelete,
  ...props
}: ApiSharingPartnerDrawerProps) => {
  const {
    data,
    isLoading: isLoadingDetail,
    isFetching: isFetchingDetail
  } = useGetApiSharingPartnerDetailQuery(apiSharingPartnerId!, {
    skip: !apiSharingPartnerId,
    refetchOnMountOrArgChange: true
  });
  const apiSharingPartner = data?.data;
  const isLoading = isFetchingDetail || isLoadingDetail;

  useEffect(() => {
    if (onChangeLoading) {
      onChangeLoading(isLoading);
    }
  }, [onChangeLoading, isLoading]);

  const descriptionItem = [
    {
      key: 'description',
      label: apiSharingPartnersMessages.description,
      children: apiSharingPartner?.description
    },
    {
      key: 'code',
      label: apiSharingPartnersMessages.code,
      children: apiSharingPartner?.code
    },
    {
      key: 'apiKey',
      label: apiSharingPartnersMessages.apiKey,
      children: apiSharingPartner?.apiKey
    },
    {
      key: 'status',
      label: messages.status,
      children: apiSharingPartner && <CommonStatus textClassname='text-base' value={apiSharingPartner?.isActive} />
    },
    {
      key: 'permissions',
      label: apiSharingPartnersMessages.permissions,
      children: apiSharingPartner?.permissions.map((o) => o.name).join(', ')
    }
  ];

  return (
    <Drawer
      {...props}
      width={454}
      title={''}
      extra={
        <div>
          {apiSharingPartner && (
            <ApiSharingPartnerActions
              onUpdateInfo={onUpdateInfo}
              apiSharingPartner={apiSharingPartner}
              onUpdateStatus={onUpdateStatus}
              onDelete={onDelete}
            />
          )}
        </div>
      }
      loading={isLoading}
    >
      <div className={twMerge('min-h-9 w-full', isLoading && !apiSharingPartner && 'skeleton-active')}>
        <Typography.Title className='text-2.5xl'>{apiSharingPartner?.name}</Typography.Title>
      </div>
      <div className={twMerge('mt-8 min-h-[124px] w-full', isLoading && !apiSharingPartner && 'skeleton-active')}>
        <Typography.Title className='mb-4 text-lg'>{messages.general}</Typography.Title>
        <Descriptions items={descriptionItem} column={1} labelStyle={{ minWidth: 140 }} />
      </div>
      <Divider className='mb-8 mt-2' />
    </Drawer>
  );
};

export default ApiSharingPartnerDrawer;
