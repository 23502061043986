import { Form, FormInstance, Spin } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { FormItem, message } from 'components/common';
import { SelectUsers } from 'components/users';
import { organizationUnitTicketDefaultAssignsMessages, validateMessages } from 'messages';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useUpdateOrganizationUnitTicketDefaultAssignMutation } from 'services';
import { OrganizationUnitTicketDefaultAssignDto, UpdateOrganizationUnitTicketDefaultAssignDto } from 'types';
import { organizationUnitTicketDefaultAssignValidationRules } from 'utils';

export type OrganizationUnitTicketDefaultAssignFormProps = {
  onChangeLoading?: (value: boolean) => void;
  onCreateSuccess?: () => void;
  organizationUnitTicketDefaultAssign?: OrganizationUnitTicketDefaultAssignDto;
};

export type OrganizationUnitTicketDefaultAssignFormRefProps = {
  form: FormInstance<OrganizationUnitTicketDefaultAssignFormType>;
  isLoading: boolean;
};

export type OrganizationUnitTicketDefaultAssignFormType = Omit<
  UpdateOrganizationUnitTicketDefaultAssignDto,
  'userId'
> & {
  userId?: DefaultOptionType;
};
const OrganizationUnitTicketDefaultAssignForm = forwardRef<
  OrganizationUnitTicketDefaultAssignFormRefProps,
  OrganizationUnitTicketDefaultAssignFormProps
>(({ onChangeLoading, onCreateSuccess, organizationUnitTicketDefaultAssign }, ref) => {
  useImperativeHandle(ref, () => ({
    form: form,
    isLoading: isLoadingUpdate
  }));

  const [form] = Form.useForm<OrganizationUnitTicketDefaultAssignFormType>();

  const [onUpdate, { isLoading: isLoadingUpdate }] = useUpdateOrganizationUnitTicketDefaultAssignMutation();

  const onFinish = ({ ...values }: OrganizationUnitTicketDefaultAssignFormType) => {
    const data: UpdateOrganizationUnitTicketDefaultAssignDto = {
      ...values,
      userId: values.userId?.value as number
    };

    if (values.organizationUnitTicketDefaultAssignId) {
      onUpdate({
        ...data
      })
        .unwrap()
        .then((rs) => {
          message.systemSuccess(rs.message);
          onCreateSuccess?.();
        });
    }
  };
  useEffect(() => {
    if (onChangeLoading) {
      onChangeLoading(isLoadingUpdate);
    }
  }, [onChangeLoading, isLoadingUpdate]);

  return (
    <Form
      scrollToFirstError={{ behavior: 'smooth', block: 'start' }}
      labelAlign='right'
      labelCol={{
        flex: '180px'
      }}
      requiredMark={false}
      form={form}
      name='workingScheduleForm'
      onFinish={onFinish}
      layout='horizontal'
      validateMessages={validateMessages}
    >
      <Spin spinning={isLoadingUpdate}>
        <Form.Item<OrganizationUnitTicketDefaultAssignFormType>
          noStyle
          name='organizationUnitTicketDefaultAssignId'
        ></Form.Item>
        <FormItem.FloatLabel<OrganizationUnitTicketDefaultAssignFormType>
          name='userId'
          rules={organizationUnitTicketDefaultAssignValidationRules.userId}
          label={organizationUnitTicketDefaultAssignsMessages.user}
        >
          <SelectUsers
            isAll
            defaultActiveFirstOption
            labelInValue
            allItemLabel='Không thiết lập'
            organizationUnitId={
              organizationUnitTicketDefaultAssign ? [organizationUnitTicketDefaultAssign.organizationUnitId] : []
            }
          />
        </FormItem.FloatLabel>
      </Spin>
    </Form>
  );
});
export default OrganizationUnitTicketDefaultAssignForm;
