export const requestform = {
  //RequestFilterForm
  deleteFilter: 'Xóa lọc',
  result: 'kết quả',
  statusRequestForm: 'Trạng thái phiếu yêu cầu',
  waitingConfirm: 'Chờ xác nhận',
  time: 'Thời gian',
  creatingFormTime: 'Thời gian tạo phiếu',
  fromDate: 'Từ ngày',
  toDate: 'Đến ngày',
  receptionTime: 'Thời gian tiếp nhận',
  processingTime: 'Thời gian xử lý',
  inChargeDepartment: 'Phòng đội phụ trách',
  typeOfService: 'Loại dịch vụ',
  delete: 'Xóa',
  filter: 'Bộ lọc',
  inputInformation: 'Nhập thông tin cần tìm',

  //index.jsx
  sortBy: 'sắp xếp theo',
  sort: 'Sắp xếp',
  chooseAll: 'Chọn tất cả',
  ticket: 'phiếu',
  currentChoosenTicket: 'phiếu đang chọn',
  wrongTicket: 'Báo phiếu sai',
  receiveTicket: 'Tiếp nhận hồ sơ',

  // Request Detail
  //Left
  createInformationTitle: 'Thông tin khởi tạo',
  dateRequest: 'Ngày yêu cầu:',
  ticketCreator: 'Người tạo:',
  appointmentDate: 'Ngày hẹn',
  receptionDate: 'Ngày tiếp nhận:',
  recipient: 'Người tiếp nhận:',
  corporateUnit: 'Đơn vị: ',
  completedDate: 'Ngày hoàn tất:',
  completedPersion: 'Người hoàn tất',
  processByCMIS: 'Điện lực xử lý',
  completeTitle: 'THÔNG TIN HOÀN TẤT',
  notCompleteStatus: 'Chưa hoàn thành',
  acceptChannel: 'Kênh tiếp nhận',

  //Right
  requestTicket: 'Phiếu yêu cầu #',
  answerQuestion: 'Hỏi đáp',
  notMultiplleTimeRequest: 'Không phải phiếu yêu cầu nhiều lần',
  multiplleTimeRequest: 'Phiếu yêu cầu nhiều lần',
  detailStatusRequestForm: 'Trạng thái phiếu:',
  isTranferCmisTicket: 'Phiếu chuyển CMIS: ',
  typeCmisTicket: '[Loại dịch vụ CMIS]',

  customerInformationTitle: 'Thông tin khách hàng',
  customerName: 'Họ tên KH',
  customerPhone: 'Điện thoại KH',
  customerAddress: 'Địa chỉ KH',

  customerRequestInformationTitle: 'Thông tin người yêu cầu',
  customerRequestName: 'Họ tên người Y/C',
  customerRequestPhone: 'Điện thoại người Y/C',
  numberOfCalls: 'Số lần gọi',
  customerRequestAddress: 'Địa chỉ người Y/C',

  customerRequestCIN: 'Số CCCD',
  dateOfIssue: 'Ngày cấp',
  placeOfIssue: 'Nơi cấp',

  requestContentTitle: 'Nội dung yêu cầu',
  processingTitle: 'Quá trình xử lý',

  // Button
  exportExcel: 'Xuất excel',
  printTicket: 'In phiếu',
  returnCCCorWrongTicket: 'Trả TTCSKH - Phiếu sai', // CCC - Customer Care Center: TTCSKH
  transferCollaboration: 'Chuyển phòng phối hợp',
  completedButton: 'Hoàn tất',
  acceptTicket: 'Tiếp nhận'
};
