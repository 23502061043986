import { FILE_TYPE } from './enums';

export const convertFileType = (type?: string) => {
  if (!type) return FILE_TYPE.UNKNOWN;

  if (type.startsWith('image/')) return FILE_TYPE.IMAGE;

  if (
    type === 'application/vnd.ms-excel' || // .xls
    type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // .xlsx
  ) {
    return FILE_TYPE.EXCEL;
  }

  if (type.endsWith('pdf')) {
    return FILE_TYPE.PDF;
  }

  if (type.endsWith('mp4')) {
    return FILE_TYPE.VIDEO;
  }

  if (
    type === 'application/msword' || // .doc
    type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' // .docx
  ) {
    return FILE_TYPE.DOCS;
  }

  return FILE_TYPE.UNKNOWN;
};

export const loadFileFromUrl = async (url: string): Promise<File | null> => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch file from URL: ${response.statusText}`);
    }

    const blob = await response.blob();
    const fileName = url.split('/').pop() || 'downloaded_file';
    const file = new File([blob], fileName, { type: blob.type });

    return file;
  } catch (error) {
    console.error('Error loading file:', error);
    return null;
  }
};
