import { Dropdown, MenuProps } from 'antd';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { OmniChannelMessageDto } from 'types';

type MessageOptionsProps = {
  items: MenuProps['items'];
  message: OmniChannelMessageDto;
  onForwardMessage?: (message: OmniChannelMessageDto) => void;
};
export type MessageOptionsRef = {
  open: (event: React.MouseEvent<HTMLDivElement>) => void;
};

const MessageOptions = forwardRef<MessageOptionsRef, MessageOptionsProps>(({ items }, ref) => {
  useImperativeHandle(ref, () => ({
    open: handleRightClick
  }));
  const [open, setOpen] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleRightClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const elementRect = event.currentTarget.getBoundingClientRect();

    /** Tính toán vị trí tương đối của con trỏ chuột so với phần tử*/
    const relativeX = event.clientX - elementRect.left;
    const relativeY = event.clientY - elementRect.top;

    setPosition({
      x: relativeX,
      y: relativeY
    });

    setOpen(true);
  };

  const itemsMenu = [...(items || [])];

  if (open)
    return (
      <Dropdown
        menu={{
          items: itemsMenu
        }}
        trigger={['click']}
        open={open}
        onOpenChange={setOpen}
        getPopupContainer={() => document.body}
        overlayClassName='text-sm'
        prefixCls='message-dropdown'
      >
        <div
          style={{
            position: 'absolute',
            top: `${position.y}px`,
            left: `${position.x}px`,
            zIndex: 9999
          }}
        />
      </Dropdown>
    );
  return null;
});

export default MessageOptions;
