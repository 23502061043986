import { Col, Typography, List, Tag, Button } from 'antd';
import { permissionsMessages } from 'messages';
import { memo } from 'react';
import { PermissionDto } from 'types';

type PermissionItemProps = {
  data: PermissionDto;
  onClickUpdateScope?: (value: PermissionDto) => void;
  onClickUserGroups?: (value: PermissionDto) => void;
  onClickUsers?: (value: PermissionDto) => void;
};

const PermissionItem = ({ data, onClickUpdateScope, onClickUserGroups, onClickUsers }: PermissionItemProps) => {
  return (
    <div className='flex w-full items-start justify-between'>
      <Col span={4}>
        <Typography.Paragraph className='mb-1 font-semibold'>{data.name}</Typography.Paragraph>
        <Typography.Paragraph type='secondary' className='mb-0 text-sm'>
          {data.description}
        </Typography.Paragraph>
      </Col>
      <Col span={4}>
        <Typography.Paragraph type='secondary' className='mb-1 text-xs font-semibold'>
          {permissionsMessages.group.toUpperCase()}
        </Typography.Paragraph>
        <Typography.Link className='text-sm' onClick={() => onClickUserGroups?.(data)}>
          {data.countUserGroups}
        </Typography.Link>
      </Col>
      <Col span={4}>
        <Typography.Paragraph type='secondary' className='mb-1 text-xs font-semibold'>
          {permissionsMessages.user.toUpperCase()}
        </Typography.Paragraph>
        <Typography.Link className='text-sm' onClick={() => onClickUsers?.(data)}>
          {data.countUsers}
        </Typography.Link>
      </Col>
      <Col span={4}>
        <Typography.Paragraph type='secondary' className='mb-1 text-xs font-semibold'>
          {permissionsMessages.scope.toUpperCase()}
        </Typography.Paragraph>
        {data.isApplyAll && (
          <Typography.Text type='secondary' className='text-sm'>
            {permissionsMessages.allOrganizationUnits}
          </Typography.Text>
        )}
        {!data.isApplyAll && data.organizationUnits && data.organizationUnits.length > 0 && (
          <List
            split={false}
            dataSource={data.organizationUnits}
            rowKey={(item) => item.organizationUnitId}
            renderItem={(item) => (
              <Tag className='me-1 rounded-2xl border-0 bg-backgroundColor4 text-xs text-colorTextBase'>
                {item.name}
              </Tag>
            )}
          />
        )}
      </Col>
      <Col span={4} className='text-end'>
        <Button
          size='small'
          type='link'
          className='text-sm font-normal'
          onClick={() => {
            onClickUpdateScope?.(data);
          }}
        >
          {permissionsMessages.updateScope}
        </Button>
      </Col>
    </div>
  );
};

export default memo(PermissionItem);
