import { Button, Result } from 'antd';
import { Loadable } from 'components';
import { useProfile } from 'hooks';
import { messages } from 'messages';
import { Outlet } from 'react-router-dom';
import { ROUTE } from 'routes/constants';
import { checkPermissions, checkEveryPermissions } from 'utils/check-permission';

type CheckPermissionLayoutProps = {
  permission: number | number[];
};

function CheckPermissionLayout({ permission }: CheckPermissionLayoutProps) {
  const { userPermissionIds } = useProfile();

  const isAllow =
    typeof permission === 'number'
      ? checkPermissions(userPermissionIds, permission)
      : checkEveryPermissions(userPermissionIds, permission);

  return (
    <div>
      {isAllow ? (
        <Loadable>
          <Outlet />
        </Loadable>
      ) : (
        <Result
          status='403'
          title='403'
          subTitle={messages.notAllowToAccess}
          extra={
            <Button
              type='primary'
              onClick={() => {
                window.location.href = ROUTE.HOME;
              }}
            >
              {messages.home}
            </Button>
          }
        />
      )}
    </div>
  );
}

export default CheckPermissionLayout;
