import { Button, Carousel, Modal } from 'antd';
import { CloseIcon } from 'assets';
import { useState } from 'react';
import PreviewServerFile from './ServerPreviewFile';

type PreviewFileModalProps = {
  fileList: number[];
  open: boolean;
  onCancel: () => void;
  current?: number;
  currentIndex?: number;
  isDownload?: boolean;
};
const PreviewFileModal: React.FC<PreviewFileModalProps> = ({ isDownload, currentIndex, fileList, open, onCancel }) => {
  const [slide, setSlide] = useState(currentIndex || 0);

  return (
    <Modal
      destroyOnClose
      prefixCls='preview-file-modal'
      open={open}
      footer={null}
      onCancel={onCancel}
      closeIcon={false}
    >
      <div className='relative flex h-svh w-svw'>
        <Button
          onClick={onCancel}
          shape='circle'
          type='text'
          className='absolute right-10 top-3 z-30 h-8 w-8 text-white hover:text-colorTextBase'
          icon={<CloseIcon />}
        />

        <Carousel
          afterChange={(currentSlide) => {
            setSlide(currentSlide);
          }}
          initialSlide={slide}
          adaptiveHeight
          prefixCls='preview-file-carousel'
          arrows
          infinite
          dots={false}
          rootClassName='h-full w-full'
        >
          {fileList && fileList?.map((item, index) => <PreviewServerFile isDownload={isDownload} fileId={item} />)}
        </Carousel>
      </div>
    </Modal>
  );
};
export default PreviewFileModal;
