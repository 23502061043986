import { Skeleton, Typography } from 'antd';
import { InfiniteScroll, List } from 'components/common';
import { usePermissionsUserGroups } from 'hooks';

type PermissionUserGroupListProps = {
  permissionId: number;
};

const PermissionUserGroupList = ({ permissionId }: PermissionUserGroupListProps) => {
  const { hasMore, handleLoadMore, isLoading, userGroups } = usePermissionsUserGroups({
    permissionId
  });

  return (
    <InfiniteScroll
      hasMore={hasMore}
      next={handleLoadMore}
      isLoading={isLoading}
      loader={<Skeleton active title={false} paragraph={{ rows: 3 }} />}
    >
      <List
        dataSource={userGroups}
        rowKey={(item) => item.userGroupId}
        renderItem={(item) => (
          <List.Item className='border-0 p-0 pb-4'>
            <Typography.Text>{item.name}</Typography.Text>
          </List.Item>
        )}
      />
    </InfiniteScroll>
  );
};

export default PermissionUserGroupList;
