import { createApi } from '@reduxjs/toolkit/query/react';
import { ResponsePagingDto, TicketDetail, ticketType } from 'types';
import axiosBaseQuery from 'utils/base-api';

export const requestForm = createApi({
    reducerPath: 'requestForm',
    tagTypes: ['pending', 'inprogress', 'collaboration', 'completed'],
    baseQuery: axiosBaseQuery,
    endpoints: (builder) => ({
        getPendingTicket: builder.query<ResponsePagingDto<ticketType>, number>({
            query: (pageSize) => ({
                url: '/tickets/dienluc/pending-reception',
                method: 'get',
                params: { pageSize }
            }),
            providesTags: (result) =>
                result && result.data.rows.length > 0
                    ? result.data.rows.map(() => ({
                        type: 'pending',
                    }))
                    : ['pending']
        }),
        getInProgressTicket: builder.query<ResponsePagingDto<ticketType>, number>({
            query: (pageSize) => ({
                url: '/tickets/dienluc/in-progress',
                method: 'get',
                params: { pageSize }
            }),
            providesTags: (result) =>
                result && result.data.rows.length > 0
                    ? result.data.rows.map(() => ({
                        type: 'inprogress',
                    }))
                    : ['inprogress']
        }),
        getCollaborationTicket: builder.query<ResponsePagingDto<ticketType>, number>({
            query: (pageSize) => ({
                url: '/tickets/dienluc/collaboration',
                method: 'get',
                params: { pageSize }
            }),
            providesTags: (result) =>
                result && result.data.rows.length > 0
                    ? result.data.rows.map(() => ({
                        type: 'collaboration',
                    }))
                    : ['collaboration']
        }),
        getCompletedTicket: builder.query<ResponsePagingDto<ticketType>, number>({
            query: (pageSize) => ({
                url: '/tickets/dienluc/completed',
                method: 'get',
                params: { pageSize }
            }),
            providesTags: (result) =>
                result && result.data.rows.length > 0
                    ? result.data.rows.map(() => ({
                        type: 'completed',
                    }))
                    : ['completed']
        }),
        getTicketDetail: builder.query<ResponsePagingDto<TicketDetail>, number>({
            query: (ticketDetail) => ({
                url: `/tickets/detail/${ticketDetail}`,
                method: 'get',
                // params: { ticketDetail }
            }),
        }),
    })
});

export const {
    useGetPendingTicketQuery,
    useGetCollaborationTicketQuery,
    useGetCompletedTicketQuery,
    useGetInProgressTicketQuery,
    useGetTicketDetailQuery
} = requestForm;
