'use client';

import { Result } from 'antd';
import { useEveryPermission, usePermission } from 'hooks';
import React from 'react';
import { PropsWithChildren } from 'react';
import { Drawer } from '../drawer';
import { permissionsMessages } from 'messages';

type PropsType = PropsWithChildren & {
  permission?: number;
  permissions?: number[];
  open: boolean;
  onClose: () => void;
};

function CheckPermissionContainerDrawer({ children, permission, permissions, open, onClose }: PropsType) {
  const { isEveryPermissions } = useEveryPermission(permissions);
  const { isPermission } = usePermission(permission);

  if (isPermission || isEveryPermissions) {
    return children;
  }

  return (
    <Drawer title={null} onClose={onClose}>
      <Result status={403} title={permissionsMessages.noPermission} />
    </Drawer>
  );
}

export default CheckPermissionContainerDrawer;
