import { DEFAULT_PAGE_SIZE } from 'utils';

type Props = {
  rowCount?: number;
};
const SkeletonRows = ({ rowCount = DEFAULT_PAGE_SIZE }: Props) => {
  return (
    <div className='flex flex-col gap-[1px]'>
      {Array.from({ length: rowCount }).map((_, rowIndex) => (
        <div key={`skeleton-row-${rowIndex}`} className='skeleton-active h-16 w-full'></div>
      ))}
    </div>
  );
};
export default SkeletonRows;
