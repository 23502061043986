import { SearchOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, GetProp, Row, Typography, Radio, Form, DatePicker, Input, List } from 'antd';
import dayjs from 'dayjs';
import { requestform } from 'messages';
import React, { useState } from 'react';

const RequestFilterForm = () => {
  const onChange: GetProp<typeof Checkbox.Group, 'onChange'> = (checkedValues) => {
    console.log('checked = ', checkedValues);
  };

  const [numOfResult, setNumOfResult] = useState(1);

  const actions: React.ReactNode[] = [
    <div className='flex h-full justify-between bg-[#F5F4F6] px-6'>
      <Button className='border-none bg-transparent text-[#141414] underline shadow-none hover:text-[#141414]'>
        {requestform.deleteFilter}
      </Button>
      <Button className='bg-[#1564E8] px-8 py-6 text-[#FFFFFF]'>
        {numOfResult} {requestform.result}
      </Button>
    </div>
  ];

  return (
    <Card
      className='h-full w-fit rounded-md bg-[#F5F4F6] text-center'
      title={requestform.filter}
      bordered={false}
      actions={actions}
    >
      <div className='h-[800px] overflow-x-hidden overflow-y-scroll text-center'>
        <section className='border-b-2 pb-8 pt-2 text-left'>
          <Typography.Text className='text-[18px]/[24px] font-semibold text-[#141414]'>
            {requestform.statusRequestForm}
          </Typography.Text>
          <div className='flex space-x-3 pt-[27px] align-middle'>
            <span className='tab-dot mt-1 h-2 w-2 rounded-[50%]' style={{ backgroundColor: 'orange' }}></span>
            <Typography.Text className='text-[14px]/[18px] text-[#141414]'>
              {requestform.waitingConfirm}
            </Typography.Text>
          </div>
        </section>
        <section className='border-b-2 pb-8 pt-8 text-left'>
          <Typography.Text className='text-[18px]/[24px] font-semibold text-[#141414]'>
            {requestform.time}
          </Typography.Text>

          <Form layout='vertical' className='w-full pt-8'>
            <Row gutter={32}>
              <Col span={24}>
                <Form.Item
                  label={
                    <Typography.Text className='text-[16px]/[20px] font-semibold'>
                      {requestform.creatingFormTime}
                    </Typography.Text>
                  }
                >
                  <div className='flex rounded-lg border-[#EBEBED] bg-white'>
                    <div className='h-fit w-1/2 border-r px-3 pb-0 pt-2'>
                      <Typography.Text className='text-[12px]/[22px] text-[#878787]'>
                        {requestform.fromDate}
                      </Typography.Text>
                      <br />
                      <DatePicker
                        suffixIcon=''
                        className='m-0 min-h-0 w-full rounded-none border-none p-0 pb-[8px]'
                        value={dayjs()}
                        form='DD/MM/YYYY'
                      />
                    </div>
                    <div className='h-fit w-1/2 px-3 pb-0 pt-2'>
                      <Typography.Text className='text-[12px]/[22px] text-[#878787]'>
                        {requestform.toDate}
                      </Typography.Text>
                      <br />
                      <DatePicker
                        suffixIcon=''
                        className='m-0 min-h-0 w-full rounded-none border-none p-0 pb-[10px]'
                        value={dayjs()}
                      />
                    </div>
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label={
                    <Typography.Text className='text-[16px]/[20px] font-semibold'>
                      {requestform.receptionTime}
                    </Typography.Text>
                  }
                >
                  <div className='flex rounded-lg border-[#EBEBED] bg-white'>
                    <div className='h-fit w-1/2 border-r px-3 pb-0 pt-2'>
                      <Typography.Text className='text-[12px]/[22px] text-[#878787]'>
                        {requestform.fromDate}
                      </Typography.Text>
                      <br />
                      <DatePicker
                        suffixIcon=''
                        className='m-0 min-h-0 w-full rounded-none border-none p-0 pb-[8px]'
                        value={dayjs()}
                        form='DD/MM/YYYY'
                      />
                    </div>
                    <div className='h-fit w-1/2 px-3 pb-0 pt-2'>
                      <Typography.Text className='text-[12px]/[22px] text-[#878787]'>
                        {requestform.toDate}
                      </Typography.Text>
                      <br />
                      <DatePicker
                        suffixIcon=''
                        className='m-0 min-h-0 w-full rounded-none border-none p-0 pb-[10px]'
                        value={dayjs()}
                      />
                    </div>
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </section>
        <section className='border-b-2 pb-8 pt-8 text-left'>
          <Typography.Text className='text-[18px]/[24px] font-semibold text-[#141414]'>
            {requestform.processingTime}
          </Typography.Text>
          <Checkbox.Group className='w-full pt-[20px]' onChange={onChange}>
            <Row gutter={[0, 24]}>
              {processingStatus.map((status, index) => (
                <Col key={index} span={12}>
                  <Checkbox className='text-[16px]/[20px]' value={status}>
                    {status}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </section>
        <section className='border-b-2 pb-8 pt-8 text-left'>
          <Typography.Text className='text-[18px]/[24px] font-semibold text-[#141414]'>
            {requestform.inChargeDepartment}
          </Typography.Text>

          <Radio.Group className='w-full pt-[20px]' defaultValue={processingTeam[0]}>
            <Row gutter={[0, 16]}>
              {processingTeam.map((status, index) => (
                <Col key={index} span={12}>
                  <Radio value={status}>{status}</Radio>
                </Col>
              ))}
            </Row>
          </Radio.Group>
        </section>

        <section className='pb-8 pt-8 text-left'>
          <Typography.Text className='text-[18px]/[24px] font-semibold text-[#141414]'>
            {requestform.typeOfService}
          </Typography.Text>

          <div className='pt-[20px]'>
            <Input
              className='w-fukk h-[40px] bg-white'
              placeholder={requestform.sortBy}
              suffix={<SearchOutlined className='text-[#B2B9C4]' />}
              variant='borderless'
            />

            <List>
              {typeServiceList.map((type, item) => (
                <List.Item className='flex justify-between'>
                  {type}{' '}
                  <Button className='border-none bg-transparent underline hover:text-[#141414]'>
                    {requestform.delete}
                  </Button>
                </List.Item>
              ))}
            </List>
          </div>
        </section>
      </div>
    </Card>
  );
};

export default RequestFilterForm;

const processingStatus = ['Còn hạn', 'Quá hạn', 'Gần hết hạn', 'Tạm ngưng'];
const processingTeam = ['Đội A', 'Đội B', 'Đội C', 'Đội kinh doanh 1', 'Đội kinh doanh 2'];
const typeServiceList = [
  'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
  'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
  'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
  'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
];
