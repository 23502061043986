import React from 'react';
import { twMerge } from 'tailwind-merge';
import { Image as ImageAntd } from 'antd';
type ImageProps = {
  url?: string;
  alt?: string;
  className?: string;
  preview?: boolean;
};
const Image: React.FC<ImageProps> = ({ preview = false, url, alt, className }) => {
  return (
    <ImageAntd
      loading='lazy'
      wrapperClassName='h-full w-full flex items-center justify-center'
      className={twMerge('h-full w-auto rounded-sm object-contain', className)}
      src={url}
      alt={alt}
      preview={preview}
    />
  );
};
export default Image;
