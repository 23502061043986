import { Spin, Typography } from 'antd';
import { Loadable } from 'components/common';
import { OmniChatProvider } from 'contexts';
import { omniChatMessages } from 'messages';
import { lazy, useEffect, useState } from 'react';
import {
  connectOmniChannelSocket,
  disconnectOmniChannelSocket,
  offReceiveChatSessionProcessing,
  onReceiveChatSessionProcessing,
  useReceiveChatSessionMutation
} from 'services';
import { twMerge } from 'tailwind-merge';
import OmniChatSession from './OmniChatSession';
import { useOmniChatSessionsProcessingList, useOmniChatSessionsWaitingList, useProfile } from 'hooks';
import { ChatSessionDto } from 'types';
import { sumBy } from 'lodash';
import { ChatIcon } from 'assets';
import { CHAT_STATUS } from 'utils';
const CustomerWaitingSupportList = lazy(() => import('./OmniChatSessionsWaiting'));
const OmniChatSessionsProcessing = lazy(() => import('./OmniChatSessionsProcessing'));

const OmniChannelChat = () => {
  const { profile } = useProfile();
  const [chatSession, setChatSession] = useState<ChatSessionDto>();
  const { chatSessionProcessing, isLoading: isLoadingProcessing } = useOmniChatSessionsProcessingList({ keyword: '' });
  const { chatSessionWaiting, isLoading: isLoadingWaiting, refetch } = useOmniChatSessionsWaitingList({ keyword: '' });

  useEffect(() => {
    connectOmniChannelSocket();
    return () => {
      disconnectOmniChannelSocket();
    };
  }, []);

  useEffect(() => {
    if (!chatSession) {
      refetch();
    }
  }, [chatSession]);

  useEffect(() => {
    onReceiveChatSessionProcessing((data) => {
      if (data.chatSessionId === chatSession?.chatSessionId) {
        if (data.status === CHAT_STATUS.COMPLETED) {
          setChatSession(undefined);
        } else {
          setChatSession(data);
        }
      }
    });
  }, [chatSession?.chatSessionId]);

  if (isLoadingProcessing || isLoadingWaiting) {
    return (
      <div className='relative flex h-full w-[400px] overflow-hidden'>
        <div className='absolute left-1/2 top-1/3 flex -translate-x-1/2 -translate-y-1/3 flex-col items-center'>
          <Spin></Spin>
        </div>
      </div>
    );
  }

  if (!chatSessionProcessing.length && !chatSessionWaiting.length) {
    return (
      <div className='relative flex h-full w-[400px] overflow-hidden'>
        <div className={twMerge('mb-4 mt-5 flex w-full justify-center')}>
          <Typography.Text type='secondary' className='text-xs font-semibold'>
            {profile?.shortName}
          </Typography.Text>
        </div>

        <div className='absolute left-1/2 top-1/3 flex -translate-x-1/2 -translate-y-1/3 flex-col items-center'>
          <ChatIcon width={80} height={80} className='text-[#d4d8de]' />
          <Typography.Title level={5} className='mb-2 mt-5'>
            {omniChatMessages.notHaveWaitingCustomer}
          </Typography.Title>
        </div>
      </div>
    );
  }

  return (
    <div className='relative flex h-full w-[400px] overflow-hidden'>
      <div
        className={twMerge(
          'absolute h-full w-full bg-white transition-all duration-200',
          !chatSession ? 'translate-x-0' : '-translate-x-full'
        )}
      >
        <div className='flex h-full w-full flex-col'>
          <div className='max-h-[calc(100%-124px)] min-h-[calc(100%-124px)]'>
            <OmniChatSessionsProcessing
              setChatSession={(value) => {
                setChatSession(value);
              }}
              chatIdSession={chatSession?.chatSessionId}
            />
          </div>

          {!chatSession && (
            <div className='h-[124px]] border-t'>
              <CustomerWaitingSupportList />
            </div>
          )}
        </div>
      </div>

      <div
        className={twMerge(
          'absolute h-full w-full bg-colorBgSuccess transition-all duration-200',
          !chatSession ? 'translate-x-full' : 'translate-x-0'
        )}
      >
        {chatSession && (
          <Loadable>
            <OmniChatProvider
              setChatSession={(value: ChatSessionDto) => setChatSession(value)}
              endpointName={''}
              chatSession={chatSession}
            >
              <OmniChatSession
                key={chatSession.chatId}
                chatSessionId={chatSession.chatSessionId}
                chatId={chatSession.chatId}
                onClose={() => setChatSession(undefined)}
                unreadCount={sumBy(chatSessionProcessing, (o) =>
                  o.chatSessionId === chatSession.chatSessionId ? 0 : o.unreadCount
                )}
              />
            </OmniChatProvider>
          </Loadable>
        )}
      </div>
    </div>
  );
};
export default OmniChannelChat;
