import { Typography } from 'antd';
import { ServerFileList } from 'components/common';
import { internalAnnouncementsMessages } from 'messages';
import { twMerge } from 'tailwind-merge';

type InternalAnnouncementAttachmentProps = {
  files: number[];
  direction?: 'horizontal' | 'vertical';
};
const InternalAnnouncementAttachment = ({ files, direction }: InternalAnnouncementAttachmentProps) => {
  return (
    <div
      className={twMerge(
        'flex w-full border-t',
        direction === 'vertical' ? 'flex-col gap-y-4 pt-8' : 'h-[96px] flex-row items-center'
      )}
    >
      <div className='min-w-[100px] flex-1'>
        <Typography.Title className={twMerge('m-0', direction === 'vertical' ? 'text-lg' : 'text-sm')}>
          {internalAnnouncementsMessages.files}
        </Typography.Title>
      </div>
      <div>
        <ServerFileList
          isPreview
          fileList={files}
          itemProps={{ className: 'min-h-[60px] h-[60px]', showInfo: false }}
        />
      </div>
    </div>
  );
};

export default InternalAnnouncementAttachment;
