export const omniChatMessages = {
  nameFeature: 'Chat KH',
  customerBeingSupported: 'KH đang hỗ trợ',
  notHaveChatSessions: 'Chưa hỗ trợ KH nào',
  descriptionReceiveCustomer: 'Nhấn nhận chat để nhận hỗ trợ cho một khách hàng đang chờ',
  receiveChat: 'Nhận chat',
  waitingCustomer: 'KH đang chờ',
  complete: 'Kết thúc',
  notHaveWaitingCustomer: '0 Khách hàng đang chờ'
};
