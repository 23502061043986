import { SelectProps } from 'antd';
import { Select } from 'components/common';
import { useGetGroupCallsQuery } from 'services';

type Props = SelectProps;

const SelectOmniCallGroups = ({ ...props }: Props) => {
  const { isLoading, data } = useGetGroupCallsQuery();

  return (
    <Select
      {...props}
      loading={isLoading}
      options={
        data?.data.map((itm) => ({
          label: itm.groupName,
          value: itm.groupId
        })) || []
      }
    />
  );
};

export default SelectOmniCallGroups;
