import { Typography } from 'antd';
import { OmniChannelChat, PageHeader } from 'components';
import { useTitle } from 'hooks';
import { sidebarMenuMessages } from 'messages';
import { PropsWithChildren } from 'react';

function CustomerSupportLayout({ children }: PropsWithChildren) {
  useTitle(sidebarMenuMessages.customerSupport);

  return (
    <div>
      <PageHeader className='flex items-center gap-1'>
        <Typography.Title className='mb-0 text-header-title font-bold'>
          {sidebarMenuMessages.customerSupport}
        </Typography.Title>
      </PageHeader>
      <div className='container-list relative max-h-[calc(100vh-100px)] overflow-hidden border p-0'>
        <div className='flex max-h-full flex-1 overflow-hidden'>
          <div className='flex w-[400px] flex-col'>
            <div className='h-[224px] w-full bg-backgroundColor3'></div>
            <div className='h-full'>
              <OmniChannelChat />
            </div>
          </div>
          <div className='relative w-[calc(100%-400px)]'>
            <div className='slim-scrollbar slim-scrollbar-horizontal max-h-full overflow-auto'>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerSupportLayout;
