import { Skeleton } from 'antd';
import { InfiniteScroll } from 'components/common';
import { CheckboxOptionItem, RightSideCheckbox } from 'components/common/checkbox';
import { useServiceTypeClassifyOptions } from 'hooks';
import { FindAllServiceTypeDto, ServiceTypeCompactDto } from 'types';

type TreeCheckboxServiceTypeProps = {
  value?: number[];
  onChange: (value: number[]) => void;
  apiProps?: FindAllServiceTypeDto;
};

const TreeCheckboxServiceTypes = ({ value, onChange, apiProps, ...props }: TreeCheckboxServiceTypeProps) => {
  const { serviceTypesOptions, isLoading, hasMore, handleLoadMore, isFetching } = useServiceTypeClassifyOptions(
    apiProps || {}
  );

  const formatData = (data: ServiceTypeCompactDto[]): CheckboxOptionItem[] => {
    if (data.length === 0) return [];

    const result: CheckboxOptionItem[] = [];

    for (const option of data) {
      if (option.parentCompact) {
        const exists = result.find((res) => res.value === option.parentCompact?.serviceTypeId.toString());
        if (exists) {
          exists.children?.push({
            key: option.serviceTypeId.toString(),
            label: option.name,
            value: option.serviceTypeId.toString()
          });
        } else {
          result.push({
            key: option.parentCompact.serviceTypeId.toString(),
            label: option.parentCompact.name,
            value: option.parentCompact.serviceTypeId.toString(),
            children: [
              {
                key: option.serviceTypeId.toString(),
                label: option.name,
                value: option.serviceTypeId.toString()
              }
            ]
          });
        }
      } else {
        result.push({
          key: option.serviceTypeId.toString(),
          label: option.name,
          value: option.serviceTypeId.toString()
        });
      }
    }

    return result;
  };

  const handleChange = (selectedValue: string[]) => {
    onChange?.(selectedValue.map((val) => Number(val)));
  };

  return (
    <InfiniteScroll
      isLoading={isLoading || isFetching}
      hasMore={hasMore}
      next={handleLoadMore}
      loader={<Skeleton active title={false} paragraph={{ rows: 3 }} />}
    >
      <RightSideCheckbox
        options={formatData(serviceTypesOptions || [])}
        onChange={handleChange}
        value={value?.map((item) => item.toString())}
        selectAll
        split
      />
    </InfiniteScroll>
  );
};

export default TreeCheckboxServiceTypes;
