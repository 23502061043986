import { Rule } from 'antd/es/form';
import { OrganizationUnitTicketDefaultAssignFormType } from 'components/organization-unit-ticket-default-assigns/OrganizationUnitTicketDefaultAssignsForm';

export const organizationUnitTicketDefaultAssignValidationRules: Record<
  keyof OrganizationUnitTicketDefaultAssignFormType,
  Rule[]
> = {
  organizationUnitTicketDefaultAssignId: [{ required: true }],
  userId: [{ required: true }]
};
