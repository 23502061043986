export enum EDITOR_TOOLBARS {
  /** Hoàn tác và làm lại các thao tác. */
  UNDO_REDO = 'undo redo',

  /** Chọn định dạng văn bản như tiêu đề, đoạn văn. */
  FORMAT_SELECT = 'blocks',

  /** Chỉnh sửa định dạng văn bản (như đậm, nghiêng, màu nền). */
  TEXT_FORMAT = 'bold italic underline strikethrough ',

  /** Thay đổi phông chữ. */
  FONT_FAMILY = 'fontfamily',

  /** Thay đổi kích thước chữ. */
  FONT_SIZE = 'fontsize',

  /** Căn chỉnh văn bản: trái, giữa, phải, đều. */
  ALIGNMENT = 'alignleft aligncenter alignright alignjustify',

  /** Tạo danh sách: dạng bullet và đánh số. */
  LISTS = 'bullist numlist',

  /** Tăng hoặc giảm thụt lề văn bản. */
  OUTDENT_INDENT = 'outdent indent',

  /** Xóa định dạng hiện tại của văn bản. */
  REMOVE_FORMAT = 'removeformat',

  /** Trợ giúp TinyMCE. */
  HELP = 'help',

  /** Chèn hoặc xóa liên kết. */
  LINK = 'link unlink',

  /** Chèn và chỉnh sửa hình ảnh. */
  IMAGE = 'image',

  /** Chèn và chỉnh sửa bảng. */
  TABLE = 'table',

  /** Xem trước nội dung đã soạn thảo. */
  PREVIEW = 'preview',

  /** Chèn ngày và giờ hiện tại. */
  INSERT_DATETIME = 'insertdatetime',

  /** Tùy chỉnh màu sắc chữ và nền. */
  TEXT_COLOR = 'forecolor backcolor',

  /** Chèn phương tiện như video và audio. */
  MEDIA = 'media',

  /** Tìm kiếm và thay thế văn bản. */
  SEARCH_REPLACE = 'searchreplace',

  /** Tạo mục lục tự động. */
  TOC = 'toc',

  /** Hiển thị các khối HTML với đường viền. */
  VISUAL_BLOCKS = 'visualblocks',

  /** Hiển thị ký tự đặc biệt (khoảng trắng). */
  VISUAL_CHARS = 'visualchars',

  /** Chèn và hiển thị đoạn mã lập trình. */
  CODE_SAMPLE = 'codesample',

  /** Mở trình soạn thảo ở chế độ toàn màn hình. */
  FULLSCREEN = 'fullscreen',

  /** Thanh công cụ nhanh của TinyMCE. */
  QUICKBARS = 'quickbars',

  /** Tự động thay đổi kích thước trình soạn thảo. */
  AUTORESIZE = 'autoresize'
}
