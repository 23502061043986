import { DefaultOptionType } from 'antd/es/select';
import { Select } from 'components/common';
import { SelectProps } from 'components/common/select/Select';
import { useUserGroupsOptions } from 'hooks';
import { useState } from 'react';
import { FindAllUserGroupDto } from 'types';
import { USER_GROUP_CLASSIFY } from 'utils';

type SelectUserGroupsProps = SelectProps & Omit<FindAllUserGroupDto, 'pageIndex' | 'pageSize' | 'keyword'>;
export type UserGroupOptionType = DefaultOptionType & { userGroupClassifyId: USER_GROUP_CLASSIFY };
const SelectUserGroups = ({ organizationUnitId, ...props }: SelectUserGroupsProps) => {
  const [keyword, setKeyword] = useState('');

  const { userGroups, handleLoadMore, resetPage, isLoading, isFetching, hasMore } = useUserGroupsOptions(
    {
      keyword,
      organizationUnitId
    },
    {
      refetchOnMountOrArgChange: true
    }
  );

  const handleSearch = (val: string) => {
    resetPage();
    setKeyword(val);
  };

  return (
    <Select
      {...props}
      onLoadMore={handleLoadMore}
      loading={isLoading || isFetching}
      hasMore={hasMore}
      showSearch
      filterOption={false}
      onSearch={handleSearch}
      options={
        userGroups.map((itm) => ({
          label: itm.name,
          value: itm.userGroupId,
          userGroupClassifyId: itm.userGroupClassifyId
        })) || []
      }
      onChange={(value, option) => {
        if (props.labelInValue && value && option) {
          const groupOption = option as UserGroupOptionType;
          props.onChange?.({ ...value, userGroupClassifyId: groupOption.userGroupClassifyId }, option);
        } else {
          props.onChange?.(value, option);
        }
      }}
    />
  );
};

export default SelectUserGroups;
