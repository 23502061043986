import { Typography } from 'antd';
import emptyData from 'assets/svg/empty-data.svg';
import { messages } from 'messages';

export const customizeRenderEmpty = () => (
  <div className='flex flex-col items-center'>
    <img src={emptyData} className='h-[20vh] max-h-[280px] min-h-[80px] w-auto' />
    <Typography.Text className='mb-5 mt-10 text-0.5xl lg:mt-6'>{messages.emptyText}</Typography.Text>
    <Typography.Text type='secondary' className='mb-10 text-center'>
      {messages.emptyDescription}
    </Typography.Text>
  </div>
);
