import { createApi } from '@reduxjs/toolkit/query/react';
import {
  FindAllOrganizationUnitTicketDefaultAssignDto,
  ResponseDto,
  ResponsePagingDto,
  UpdateOrganizationUnitTicketDefaultAssignDto,
  OrganizationUnitTicketDefaultAssignDto
} from 'types';
import axiosBaseQuery from 'utils/base-api';

export const organizationUnitTicketDefaultAssignsApi = createApi({
  reducerPath: 'organizationUnitTicketDefaultAssignsApi',
  tagTypes: ['organization_unit_ticket_default_assigns', 'organization_unit_ticket_default_assigns_detail'],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getOrganizationUnitTicketDefaultAssigns: builder.query<
      ResponsePagingDto<OrganizationUnitTicketDefaultAssignDto>,
      FindAllOrganizationUnitTicketDefaultAssignDto
    >({
      query: (params) => ({
        url: '/organization_unit_ticket_default_assigns',
        method: 'get',
        params
      }),
      providesTags: (result) =>
        result && result.data.rows.length > 0
          ? result.data.rows.map(({ organizationUnitTicketDefaultAssignId }) => ({
              type: 'organization_unit_ticket_default_assigns',
              id: organizationUnitTicketDefaultAssignId
            }))
          : ['organization_unit_ticket_default_assigns']
    }),

    getOrganizationUnitTicketDefaultAssignDetail: builder.query<
      ResponseDto<OrganizationUnitTicketDefaultAssignDto>,
      number
    >({
      query: (organizationUnitTicketDefaultAssignId) => ({
        url: `/organization_unit_ticket_default_assigns/${organizationUnitTicketDefaultAssignId}`,
        method: 'get'
      }),
      providesTags: (result) =>
        result?.data
          ? [
              {
                type: 'organization_unit_ticket_default_assigns',
                id: result.data.organizationUnitTicketDefaultAssignId
              }
            ]
          : []
    }),

    updateOrganizationUnitTicketDefaultAssign: builder.mutation<
      ResponseDto<OrganizationUnitTicketDefaultAssignDto>,
      UpdateOrganizationUnitTicketDefaultAssignDto
    >({
      query: ({ organizationUnitTicketDefaultAssignId, ...updateSchedule }) => ({
        url: `/organization_unit_ticket_default_assigns/${organizationUnitTicketDefaultAssignId}`,
        method: 'patch',
        data: updateSchedule
      }),
      invalidatesTags: (_result, _error, param) => [
        { type: 'organization_unit_ticket_default_assigns', id: param.organizationUnitTicketDefaultAssignId },
        { type: 'organization_unit_ticket_default_assigns_detail', id: param.organizationUnitTicketDefaultAssignId }
      ]
    })
  })
});

export const {
  useGetOrganizationUnitTicketDefaultAssignsQuery,
  useGetOrganizationUnitTicketDefaultAssignDetailQuery,
  useUpdateOrganizationUnitTicketDefaultAssignMutation
} = organizationUnitTicketDefaultAssignsApi;
