export const usersMessages = {
  title: 'Người dùng',
  createUser: 'Tạo người dùng',
  employeeId: 'Mã nhân viên',
  username: 'Tên đăng nhập',
  fullName: 'Họ tên',
  gender: 'Giới tính',
  unit: 'Đơn vị',
  group: 'Nhóm',
  userGroup: 'Nhóm người dùng',
  organize: 'Phòng đội',
  permissions: 'Phân quyền',
  creationHistory: 'Lịch sử tạo phiếu',
  evaluationHistory: 'Lịch sử đánh giá',
  workingTime: 'Thời gian làm việc',
  currentPassword: 'Mật khẩu hiện tại',
  newPassword: 'Mật khẩu mới',
  confirmPassword: 'Xác nhận mật khẩu',
  email: 'Email',
  password: 'Mật khẩu',
  status: 'Trạng thái',
  shortName: 'Tên viết tắt',
  phoneNumber: 'Số điện thoại',
  birthday: 'Ngày sinh',
  personal: 'Cá nhân',
  roles: 'Nhóm quyền',
  avatar: 'Ảnh đại diên',
  changePassword: 'Đổi mật khẩu',
  updateInfo: 'Sửa thông tin',
  delete: 'Xóa tài khoản',
  confirmDelete: 'Bạn có chắc chắn muốn xóa người dùng này không?',
  inactiveUser: 'Đóng tài khoản',
  confirmInactive: 'Bạn có chắc chắn muốn đóng tài khoản người dùng này?',
  passwordMismatchPatern: 'Mật khẩu phải gồm ít nhất 10 ký tự, kết hợp chữ hoa, chữ thường, số và ký tự đặc biệt.',
  updateStatus: 'Cập nhật trạng thái',
  user: 'Người dùng',
  accountStatus: 'Trạng thái tài khoản',
  grantPermissions: 'Quyền được cấp',
  permissionFromGroup: 'Quyền từ nhóm',
  personalInfo: 'Thông tin cá nhân',
  loginInfo: 'Thông tin đăng nhập',
  organizationUnit: 'Đơn vị/phòng đội',
  chooseOrganizationUnitFirts: 'Chọn đơn vị/phòng đội trước',
  updateUser: 'Cập nhật người dùng',
  saveAndUpdatePermissions: 'Lưu & Tùy chỉnh phân quyền',
  extensionId: 'Extension',
  omniGroupId: 'Nhóm gọi',
  companyName: 'Công ty',
  teleAgentInfo: 'Thông tin điện thoại viên',
  cannotDelete: 'Không thể xóa người dùng',
  userHadLoginPleaseChangeStatusInstead:
    'Người dùng đã từng đăng nhập hệ thống CRM. Vui lòng thực hiện đổi trạng thái thay vì xóa tài khoản người dùng.'
};
