import { Descriptions, Divider, Spin, Tag, Typography } from 'antd';
import { Editor, OptionStatus } from 'components/common';
import { internalAnnouncementsMessages, messages } from 'messages';
import { forwardRef, useImperativeHandle } from 'react';
import { InternalAnnouncementDto } from 'types';
import InternalAnnouncementAttachment from './InternalAnnouncementAttachment';

export type InternalAnnouncementInfoProps = {
  data?: InternalAnnouncementDto;
  isLoading?: boolean;
  internalAnnouncementId?: number;
};

export type InternalAnnouncementInfoRefProps = {};

const InternalAnnouncementInfo = forwardRef<InternalAnnouncementInfoRefProps, InternalAnnouncementInfoProps>(
  ({ data, isLoading, internalAnnouncementId }, ref) => {
    useImperativeHandle(ref, () => ({}));

    const generalInfoItems = [
      {
        key: 'organizationUnit',
        label: internalAnnouncementsMessages.organizationUnit,
        children: (
          <div className='flex flex-wrap gap-y-2'>
            {data?.organizationUnits?.map((orgUnit) => (
              <Tag
                key={orgUnit.organizationUnitId}
                color='#1564E81A'
                className='rounded-2xl text-xs text-colorTextBase'
              >
                {orgUnit.name}
              </Tag>
            ))}
          </div>
        )
      },
      {
        key: 'priority',
        label: internalAnnouncementsMessages.priority,
        children: data?.priority ?? '-'
      },
      {
        key: 'status',
        label: internalAnnouncementsMessages.status,
        children: data?.status ? <OptionStatus value={data.status} /> : '-'
      },
      {
        key: 'isRequestConfirm',
        label: internalAnnouncementsMessages.isRequestConfirm,
        children: data?.isRequestConfirm ? messages.booleanEnum.true : messages.booleanEnum.false
      }
    ];

    if (isLoading) return <Spin spinning />;

    return (
      <>
        {data && internalAnnouncementId === data.internalAnnouncementId && (
          <>
            <Typography.Title className='mb-4 text-lg'>{messages.general}</Typography.Title>
            <Descriptions items={generalInfoItems} column={1} />
            <Divider className='mb-8 mt-2' />

            <Typography.Title className='mb-4 mt-0 text-lg'>{internalAnnouncementsMessages.content}</Typography.Title>
            <div className='-mx-6 mb-4 flex flex-1 flex-col px-6'>
              <Editor
                value={data.content}
                disabled={true}
                init={{
                  height: 700,
                  menubar: false, // Ẩn menu bar
                  toolbar: false, // Ẩn toolbar
                  toolbar1: '',
                  statusbar: false, // Ẩn status bar
                  plugins: [], // Không sử dụng plugin nào
                  content_style: 'body { background-color: #ffffff; }'
                }}
                toolbar={false}
              />
            </div>

            <InternalAnnouncementAttachment files={data.files} direction='vertical' />
          </>
        )}
      </>
    );
  }
);
export default InternalAnnouncementInfo;
