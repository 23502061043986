import { usePaging } from 'hooks/usePaging';
import { useCallback } from 'react';
import { useGetChatSessionsProcessingQuery } from 'services';
import { FindAllChatSessions, UseQueryOptionsType } from 'types';
const pageSize = 5;
export function useOmniChatSessionsProcessingList(
  params: Omit<FindAllChatSessions, 'pageIndex' | 'pageSize'>,
  options?: UseQueryOptionsType
) {
  const { keyword } = params;

  const { pageIndex, nextPage, resetPage } = usePaging(params);

  const {
    data: dataResponse,
    isLoading,
    isFetching
  } = useGetChatSessionsProcessingQuery(
    {
      pageIndex: pageIndex,
      pageSize,
      keyword
    },
    {
      ...options
    }
  );

  const { rows, count } = dataResponse?.data || {};

  const hasMore = Boolean(rows && count && rows.length < count);

  const handleLoadMore = useCallback(() => {
    if (hasMore && !isFetching) {
      nextPage();
    }
  }, [hasMore, pageIndex, isFetching]);

  return {
    chatSessionProcessing: rows || [],
    count: count || 0,
    isFetching,
    isLoading,
    handleLoadMore,
    hasMore,
    resetPage
  };
}
