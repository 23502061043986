import { MenuProps, Typography } from 'antd';
import { DownloadImageIcon } from 'assets';
import { OmniMessageText, File } from 'components/common';
import dayjs from 'dayjs';
import { useProfile } from 'hooks';
import { internalChatMessages } from 'messages';
import { memo, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { FileDto, OmniChannelMessageDto } from 'types';
import { fileServerDownload, MESSAGE_IMAGE_HEIGHT_LARGE, TIME_FORMAT } from 'utils';
import MessageOptions, { MessageOptionsRef } from './MessageOptions';

type OmniMessageImageProps = {
  fileId?: number;
  file?: string;
  sentAt?: string | Date;
  onForwardMessage?: (message: OmniChannelMessageDto) => void;
  data: OmniChannelMessageDto;
};

const OmniMessageImage: React.FC<OmniMessageImageProps> = ({ onForwardMessage, data, fileId, file, sentAt }) => {
  const { profile } = useProfile();

  const [fileLoaded, setFileLoaded] = useState<FileDto>();
  const optionsRef = useRef<MessageOptionsRef>(null);

  const isMe = typeof data.senderId === 'number' && data.senderId === profile?.userId;
  const isHasContent = Boolean(data.content);
  const items: MenuProps['items'] = [
    {
      key: 'copy',
      label: internalChatMessages.download,
      onClick: () => {
        if (fileId && fileLoaded) {
          fileServerDownload(fileLoaded);
        } else if (file) {
          fileServerDownload({
            url: file
          });
        }
      },
      icon: <DownloadImageIcon />
    }
  ];

  return (
    <div
      onContextMenu={(e) => {
        optionsRef.current?.open(e);
      }}
      className='relative'
    >
      <div
        className={twMerge(
          'overflow-hidden rounded-lg',
          isMe
            ? 'border-colorBgMyMessage bg-colorBgMyMessage'
            : 'border-colorBgCustomerMessage bg-colorBgCustomerMessage',
          isHasContent ? 'border' : 'border-0 bg-transparent',
          data.content && 'rounded-b-none'
        )}
      >
        <div
          className='relative overflow-hidden'
          style={{
            height: MESSAGE_IMAGE_HEIGHT_LARGE,
            width: isHasContent ? MESSAGE_IMAGE_HEIGHT_LARGE : 'fit-content',
            maxWidth: MESSAGE_IMAGE_HEIGHT_LARGE
          }}
        >
          {isHasContent && (
            <>
              {fileId ? (
                <img
                  alt='message-file-content'
                  src={fileLoaded?.url}
                  className='absolute h-full w-full rounded-lg opacity-70 blur-xl'
                />
              ) : file ? (
                <img
                  alt='message-file-content'
                  src={file}
                  className='absolute h-full w-full rounded-lg opacity-70 blur-xl'
                />
              ) : null}
            </>
          )}
          {fileId ? (
            <File.Server
              onDataFetched={(file) => {
                setFileLoaded(file);
              }}
              fileId={Number(fileId)}
              fileClassName={twMerge(isHasContent ? 'rounded-md' : 'rounded-lg')}
              className={twMerge(
                'h-full w-full items-center border-0 p-0',
                isHasContent ? 'items-center' : isMe ? 'items-end' : 'items-start'
              )}
              isPreviewImage
            />
          ) : file ? (
            <File
              fileClassName={twMerge(isHasContent ? 'rounded-md' : 'rounded-lg')}
              className={twMerge(
                'h-full w-full items-center border-0 p-0',
                isHasContent ? 'items-center' : isMe ? 'items-end' : 'items-start'
              )}
              url={file}
              type={'image/png'}
              isPreviewImage
            />
          ) : null}
        </div>
      </div>
      {data.content?.length && data.content?.length > 0 ? (
        <div>
          <OmniMessageText isImageMessage data={data} className='max-w-[362px] rounded-t-none' />
        </div>
      ) : (
        <div className={twMerge('absolute bottom-1 left-1 rounded-2xl bg-textHoverBg px-1')}>
          <Typography.Paragraph className='mb-0 text-xs' type='secondary'>
            {dayjs(sentAt).format(TIME_FORMAT)}
          </Typography.Paragraph>
        </div>
      )}
      <MessageOptions onForwardMessage={onForwardMessage} ref={optionsRef} items={items} message={data} />
    </div>
  );
};

export default memo(OmniMessageImage);
