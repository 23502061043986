import { Button, Spin, Typography } from 'antd';
import { useFile } from 'hooks/files';
import { PropsWithChildren, useEffect } from 'react';
import { FileDto } from 'types';
import { FileData } from './File';
import { convertFileType, FILE_SYMBOL, FILE_TYPE, fileServerDownload } from 'utils';
import Image from './Image';
import { find } from 'lodash';
import { colorsBase } from 'themes';
import { DownloadOutlined, FileOutlined } from '@ant-design/icons';

type FileProps = PropsWithChildren & {
  fileId?: number;
  className?: string;
  onDataFetched?: (file: FileDto) => void;
  preview?: (data: FileData) => void;
  isDownload?: boolean;
};
const PreviewServerFile = ({ fileId, className, preview, onDataFetched, isDownload, ...props }: FileProps) => {
  const { file, isLoading, isError: isErrorGet } = useFile(fileId);
  useEffect(() => {
    if (file && onDataFetched) {
      onDataFetched(file);
    }
  }, [file]);

  const renderFile = () => {
    switch (convertFileType(file?.fileType)) {
      case FILE_TYPE.IMAGE:
        return <Image url={file?.url} alt={file?.originalName ?? file?.objectName}></Image>;
      case FILE_TYPE.VIDEO:
        return <embed src={file?.url} type={file?.fileType} width='100%' height='100%' />;
      case FILE_TYPE.PDF:
        return <embed src={file?.url} type={file?.fileType} width='100%' height='100%' />;

      default:
        return <div className='rounded-base bg-colorBgContainerDisabled p-5'>Không thể xem trước file</div>;
    }
  };
  const itemShowed = find(FILE_SYMBOL, {
    value: file ? convertFileType(file?.fileType) : FILE_TYPE.UNKNOWN
  });
  if (!file && !isErrorGet)
    return <div className='rounded-base bg-colorBgContainerDisabled p-5'>Không thể xem trước file</div>;
  return (
    <Spin wrapperClassName='h-full w-full' spinning={isLoading}>
      <div className='flex h-14 items-center gap-4 px-10 pr-20'>
        <div
          className='flex h-8 w-8 items-center justify-center rounded text-white'
          style={{
            background: itemShowed?.color || colorsBase.colorPrimary
          }}
        >
          {itemShowed?.icon || <FileOutlined className='text-white' />}
        </div>
        <Typography.Text className='text-lg text-white'>{file?.originalName || file?.objectName || ''}</Typography.Text>
        {isDownload && (
          <Button
            shape='circle'
            type='text'
            className='ml-auto h-8 w-8 text-white hover:text-colorTextBase'
            icon={<DownloadOutlined></DownloadOutlined>}
            onClick={() => {
              if (file) {
                fileServerDownload(file);
              }
            }}
          />
        )}
      </div>
      <div className='flex h-[calc(100vh-56px)] w-full items-center justify-center px-10 pb-10'>{renderFile()}</div>
    </Spin>
  );
};
export default PreviewServerFile;
