import { FindAllOmniMessagesDto } from './../../types/dto/omni-channel/find-all-omni-messages.dto';
import { useCallback } from 'react';
import { useLazyGetOmniChatMessagesQuery } from 'services';
import { ChatSessionDto } from 'types';
import { MESSAGE_LIMIT_LOAD, OMNI_MESSAGE_QUERY_TYPE } from 'utils';
const limit = MESSAGE_LIMIT_LOAD;
export function useLazyOmniMessages(chatSession: ChatSessionDto) {
  const [trigger, { data: dataResponse, isLoading, isFetching, originalArgs, endpointName }] =
    useLazyGetOmniChatMessagesQuery({
      refetchOnReconnect: true
    });

  const { rows, count } = dataResponse?.data || {};
  const hasMore = Boolean(rows && count && rows.length < count);
  const endMessage = rows && rows[rows.length - 1];
  const hasNew = useCallback(
    () =>
      endMessage?.messageId
        ? endMessage?.chatId === chatSession.chatId && endMessage?.messageId !== chatSession.lastMessageId
        : false,
    [chatSession, endMessage]
  )();

  const getOlderMess = useCallback(
    (value?: Partial<FindAllOmniMessagesDto>) => {
      return trigger({
        ...(originalArgs as FindAllOmniMessagesDto),
        limit,
        skip: 0,
        sortOrder: OMNI_MESSAGE_QUERY_TYPE.OLDER,
        // message: undefined,
        startMessageId: rows?.[0]?.messageId,
        ...value
      });
    },
    [hasMore, originalArgs, rows]
  );

  const getNewerMess = useCallback(
    (value?: Partial<FindAllOmniMessagesDto>) => {
      return trigger({
        ...(originalArgs as FindAllOmniMessagesDto),
        limit,
        skip: 0,
        sortOrder: OMNI_MESSAGE_QUERY_TYPE.NEWER,
        // message: undefined,
        startMessageId: rows?.[rows.length - 1]?.messageId,
        ...value
      });
    },
    [hasMore, originalArgs, rows]
  );

  const fetchData = useCallback(
    (value: FindAllOmniMessagesDto) => {
      return trigger({
        ...originalArgs,
        limit,
        skip: 0,
        sortOrder: undefined,
        // message: undefined,
        ...value
      });
    },
    [trigger]
  );
  return {
    data: rows || [],
    count: count || 0,
    isLoading: isLoading,
    hasMore,
    hasNew,
    fetchData,
    getOlderMess,
    isFetching: isFetching,
    originalArgs,
    endpointName,
    getNewerMess
  };
}
