import { ChatSessionDto, OmniChannelMessageDto, OmniChannelSenderDto } from 'types';
import { MessagePosition } from 'types/message-position.type';
import { MESSAGE_SPACING, MESSAGE_SPACING_LARGE } from 'utils/constants';
import { calculateHeightOmniMessageItem } from './calculateHeightOmniMessageItem';

export const getPositionsOmniMessageItems = (
  messages: OmniChannelMessageDto[],
  conversation?: ChatSessionDto,
  profileId?: number
): {
  positions: MessagePosition[];
  height: number;
} => {
  if (messages.length > 0) {
    let arrPositions = new Array(messages.length).fill({
      height: 50
    });
    let totalHeight = 0;

    messages.forEach((message, index) => {
      const nextMess = messages[index + 1];
      const prevMess = messages[index - 1];
      const sender: OmniChannelSenderDto = {
        senderAvatar: message.senderAvatar,
        senderId: message.senderId,
        senderName: message.senderName
      };
      const isGroup = true;
      const heightMessage = calculateHeightOmniMessageItem(message, nextMess, prevMess, isGroup, profileId, sender);
      /** Khoảng cách giữa các tin nhắn của cùng người gửi hay khác người gửi */
      const spacing = message.senderId !== nextMess?.senderId ? MESSAGE_SPACING_LARGE : MESSAGE_SPACING;

      const prevItem = arrPositions[index - 1];
      totalHeight += heightMessage;

      const position = prevItem ? (prevItem.position ? prevItem.position + prevItem.height : prevItem.height) : 0;
      arrPositions[index] = {
        messageId: message.messageId,
        height: heightMessage,
        position,
        spacing
      };
    });
    return {
      positions: arrPositions,
      height: totalHeight
    };
  }
  return {
    positions: [],
    height: 0
  };
};
