import { Button, Modal, ModalProps } from 'antd';
import { CloseIcon } from 'assets';
import React from 'react';
type SmallModalProps = ModalProps;
const SmallModal: React.FC<SmallModalProps> = ({ title, ...props }) => {
  return (
    <Modal
      {...props}
      prefixCls='small-modal'
      title={
        <div className='relative flex w-full items-center'>
          <div className='flex-1 text-center'>{title}</div>
          <Button
            onClick={props.onCancel}
            icon={<CloseIcon />}
            type='text'
            className='absolute right-4 h-8 w-8'
            shape='circle'
          />
        </div>
      }
    />
  );
};
export default SmallModal;
