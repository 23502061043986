import { List, Skeleton, Typography } from 'antd';
import { InfiniteScroll } from 'components/common';
import { useLazyOrganizationUnitServiceTypesHierachy } from 'hooks';
import { useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import { ServiceTypeCompactDto } from 'types';

type OrganizationUnitServiceTypeListProps = {
  organizationUnitId: number;
};

const OrganizationUnitServiceTypeList = ({ organizationUnitId }: OrganizationUnitServiceTypeListProps) => {
  const {
    data: serviceTypesOptions,
    isLoading,
    hasMore,
    handleLoadMore,
    isFetching,
    fetchData
  } = useLazyOrganizationUnitServiceTypesHierachy();

  useEffect(() => {
    if (organizationUnitId) {
      fetchData({
        organizationUnitId: organizationUnitId
      });
    }
  }, [organizationUnitId]);
  const formatData = (data: ServiceTypeCompactDto[]): ServiceTypeCompactDto[] => {
    if (data.length === 0) return [];

    const result: ServiceTypeCompactDto[] = [];

    for (const option of data) {
      if (option.parentCompact) {
        const exists = result.find((res) => res.serviceTypeId === option.parentCompact?.serviceTypeId);
        if (exists) {
          exists.childrenCompact?.push(option);
        } else {
          result.push({
            ...option.parentCompact,
            childrenCompact: [option]
          });
        }
      } else {
        result.push(option);
      }
    }

    return result;
  };

  return (
    <InfiniteScroll
      isLoading={isLoading || isFetching}
      hasMore={hasMore}
      next={handleLoadMore}
      loader={<Skeleton active title={false} paragraph={{ rows: 3 }} />}
    >
      <List
        dataSource={formatData(serviceTypesOptions || [])}
        rowKey={(item) => item.serviceTypeId}
        renderItem={(item) => (
          <List.Item className='mb-5 pt-0'>
            <div>
              <Typography.Paragraph
                className={twMerge(
                  'my-2',
                  item.childrenCompact && item.childrenCompact.length > 0
                    ? 'text-xs font-semibold uppercase text-subTextColor'
                    : ''
                )}
              >
                {item.name}
              </Typography.Paragraph>
              {item.childrenCompact?.map((child) => (
                <div key={child.serviceTypeId}>
                  <Typography.Paragraph className='my-3'>{child.name}</Typography.Paragraph>
                </div>
              ))}
            </div>
          </List.Item>
        )}
      />
    </InfiniteScroll>
  );
};

export default OrganizationUnitServiceTypeList;
