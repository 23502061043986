import { SelectProps } from 'antd';
import { Select } from 'components/common';
import { useServiceTypesCMIS } from 'hooks';
import { useState } from 'react';

type SelectServiceTypeCMISProps = SelectProps;

const SelectServiceTypeCMIS = ({ ...props }: SelectServiceTypeCMISProps) => {
  const [keyword, setKeyword] = useState('');

  const { serviceTypeCMIS, hasMore, handleLoadMore, resetPage, isLoading, isFetching } = useServiceTypesCMIS({
    code: keyword
  });

  const handleSearch = (val: string) => {
    resetPage();
    setKeyword(val);
  };

  return (
    <Select
      {...props}
      onLoadMore={handleLoadMore}
      loading={isLoading || isFetching}
      showSearch
      filterOption={false}
      onSearch={handleSearch}
      hasMore={hasMore}
      options={
        serviceTypeCMIS.map((itm) => ({
          label: itm.name,
          value: itm.serviceTypeCmisId
        })) || []
      }
    />
  );
};

export default SelectServiceTypeCMIS;
