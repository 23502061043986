const env = window.env ?? process.env;
export const configuration = {
  NODE_ENV: env.NODE_ENV,
  API_URL: env.REACT_APP_API_URL,
  CHAT_SOCKET_URL: env.REACT_APP_CHAT_SOCKET_URL,
  NOTIFICATION_SOCKET_URL: env.REACT_APP_NOTIFICATION_SOCKET_URL,
  OMNI_CHANNEL_SOCKET_URL: env.REACT_APP_OMNI_CHANNEL_SOCKET_URL,
  UPLOAD_SIZE_LIMIT_MB: env.REACT_APP_UPLOAD_SIZE_LIMIT_MB,
  OMNI_CHANNEL_CHAT_SESSION_LIMIT: env.REACT_APP_OMNI_CHANNEL_CHAT_SESSION_LIMIT
};
