import { useProfile } from 'hooks';
import { internalChatMessages } from 'messages';
import { memo } from 'react';
import { twMerge } from 'tailwind-merge';
import { OmniChannelMessageDto, OmniChannelSenderDto } from 'types';

type OmniMessageSystemProps = {
  data: OmniChannelMessageDto;
  sender?: OmniChannelSenderDto;
};

const OmniMessageSystem: React.FC<OmniMessageSystemProps> = ({ data, sender }) => {
  const { profile } = useProfile();
  const isMe = typeof data.senderId === 'number' && data.senderId === profile?.userId;

  return (
    <div
      className={twMerge(
        'relative min-w-[70px] max-w-[700px] whitespace-pre-wrap break-words px-3 py-2 text-center text-sm text-white'
      )}
    >
      <div
        dangerouslySetInnerHTML={{ __html: `${isMe ? internalChatMessages.you : sender?.senderName} ${data.content}` }}
      />
    </div>
  );
};

export default memo(OmniMessageSystem);
