import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addMessageOmniChat, updateMessageOmniChat, useSendOmniChatMessageMutation } from 'services';
import { AppDispatch } from 'store';
import { CreateOmniChannelMessageDto, FileUpload, OmniChannelMessageDto } from 'types';
import { useUploadForm } from 'hooks/useUploadForm';
export function useSentOmniChatMessage(chatId: number, chatSessionId: number, endpointNameMessages?: string) {
  const dispatch = useDispatch<AppDispatch>();
  const { handleMultiUpload } = useUploadForm();

  const [sendMessage] = useSendOmniChatMessageMutation();

  const dispatchAddMessage = useCallback(
    (data: OmniChannelMessageDto) => {
      if (endpointNameMessages) dispatch(addMessageOmniChat(data, endpointNameMessages, { chatId, chatSessionId }));
    },
    [endpointNameMessages]
  );

  const dispatchUpdateMessage = useCallback(
    (data: OmniChannelMessageDto, tempMessageId: number) => {
      if (endpointNameMessages)
        dispatch(updateMessageOmniChat(data, tempMessageId, endpointNameMessages, { chatId, chatSessionId }));
    },
    [endpointNameMessages]
  );

  const handleAddOmniMessage = (data: OmniChannelMessageDto) => {
    dispatchAddMessage(data);
  };

  const handleUpdateAddOmniMessage = (data: OmniChannelMessageDto, tempMessageId: number) => {
    dispatchUpdateMessage(data, tempMessageId);
  };

  const handleSentOmniMessage = async (data: Omit<CreateOmniChannelMessageDto, 'fileId'> & { fileId?: FileUpload }) => {
    const { fileId } = data;
    const uploadedOtherFiles = fileId ? await handleMultiUpload([fileId]) : [];

    return handleSend({
      chatSessionId,
      messageType: data.messageType,
      content: data.content,
      fileId: uploadedOtherFiles[0]
    });
  };

  const handleSend = (data: CreateOmniChannelMessageDto) => {
    return sendMessage({
      ...data
    }).unwrap();
  };

  return {
    handleAddOmniMessage,
    handleUpdateAddOmniMessage,
    handleSentOmniMessage
  };
}
