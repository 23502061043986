import { Button, Form } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ExcelFileIcon } from 'assets';
import { Select, Table } from 'components/common';
import { useElectricityPaymentLocationPaging } from 'hooks/customer-support-information';
import { useFile } from 'hooks/files';
import { customerSupportInformationMessages, messages } from 'messages';
import { useGetDistrictsQuery, useGetElectricalPaymentLocationCategoriesQuery } from 'services';
import { twMerge } from 'tailwind-merge';
import { ElectricityPaymentLocationDto } from 'types';
import { fileServerDownload, ITEM_SELECT_ALL } from 'utils';

type ElectricityPaymentLocationsProps = {
  showExport?: boolean;
  showSearch?: boolean;
  fileExcelId?: number;
};

const ElectricityPaymentLocations = ({
  showExport = true,
  showSearch = true,
  fileExcelId
}: ElectricityPaymentLocationsProps) => {
  const [form] = Form.useForm();
  const district = Form.useWatch('district', form) ?? undefined;
  const category = Form.useWatch('category', form);

  const { isLoading: isLoadingDistrict, data: districts } = useGetDistrictsQuery({});

  const { isLoading: isLoadingCategory, data: categories } = useGetElectricalPaymentLocationCategoriesQuery({
    district: district
  });

  const categoryOptions = categories?.data.rows ?? [];
  const districtOptions = districts?.data.rows ?? [];

  const {
    electricityPaymentLocations,
    isLoading: isLoadingContent,
    handleChangePage,
    count,
    pageIndex
  } = useElectricityPaymentLocationPaging({
    district,
    category
  });

  const { file } = useFile(fileExcelId);

  const columns: ColumnsType<ElectricityPaymentLocationDto> = [
    {
      title: customerSupportInformationMessages.serialNumber,
      dataIndex: 'electricityPaymentLocationId'
    },
    {
      title: customerSupportInformationMessages.locationName,
      dataIndex: 'locationName',
      render: (value) => value ?? '-'
    },
    {
      title: customerSupportInformationMessages.address,
      dataIndex: 'address',
      render: (value) => value ?? '-'
    },
    {
      title: customerSupportInformationMessages.district,
      dataIndex: 'district',
      render: (value) => value ?? '-'
    },
    {
      title: customerSupportInformationMessages.phoneNumber,
      dataIndex: 'phone',
      render: (value) => value ?? '-'
    }
  ];

  return (
    <div className='w-full'>
      <div className={twMerge('relative left-1/2 mb-5 flex h-fit -translate-x-1/2 items-center rounded-lg')}>
        {showSearch && (
          <Form form={form} layout='inline' initialValues={{ district: null }}>
            <Form.Item name='district' className='min-w-[200px]'>
              <Select
                loading={isLoadingDistrict}
                placeholder={customerSupportInformationMessages.district}
                options={[
                  ITEM_SELECT_ALL,
                  ...districtOptions.map((option) => ({
                    label: option.administrativeUnitName,
                    value: option.administrativeUnitName
                  }))
                ]}
              />
            </Form.Item>
            <Form.Item name='category' className='min-w-[200px]'>
              <Select
                allowClear
                loading={isLoadingCategory}
                placeholder={customerSupportInformationMessages.category}
                options={categoryOptions.map((option) => ({ key: option, label: option, value: option }))}
              />
            </Form.Item>
          </Form>
        )}
        {showExport && (
          <Button
            type='link'
            className='ml-auto h-10 font-normal text-colorTextBase'
            icon={<ExcelFileIcon className='h-4 w-4' />}
            disabled={!file?.url}
            onClick={() => {
              if (file) {
                fileServerDownload(file);
              }
            }}
          >
            {messages.exportExcel}
          </Button>
        )}
      </div>
      <Table
        loading={isLoadingContent}
        columns={columns}
        count={count}
        dataSource={electricityPaymentLocations}
        currentPage={pageIndex}
        handleChangePage={handleChangePage}
      />
    </div>
  );
};

export default ElectricityPaymentLocations;
