import { OmniChannelMessageDto, OmniChannelSenderDto } from 'types';
import { MESSAGE_DATE_HEIGHT, MESSAGE_FILE_HEIGHT, MESSAGE_SPACING, MESSAGE_SPACING_LARGE } from 'utils/constants';
import { MESSAGE_TYPE } from 'utils/enums';
import { calculateHeightOmniMessageImage } from './calculateHeightOmniMessageImage';
import { calculateHeightOmniMessageSystem } from './calculateHeightOmniMessageSystem';
import { calculateHeightOmniMessageText } from './calculateHeightOmniMessageText';
import { getPropsOmniMessageItem } from './getPropsOmniMessageItem';

export const calculateHeightOmniMessageItem = (
  message: OmniChannelMessageDto,
  nextMess?: OmniChannelMessageDto | null,
  prevMess?: OmniChannelMessageDto | null,
  isGroup?: boolean,
  profileId?: number,
  sender?: OmniChannelSenderDto
): number => {
  let heightMessage = 0;

  const { isFirstOfSender, isOnSameDate } = getPropsOmniMessageItem(message, nextMess, prevMess);
  const isMe = typeof message.senderId === 'number' && message.senderId === profileId;

  switch (message.messageType) {
    case MESSAGE_TYPE.SYSTEM:
      heightMessage = calculateHeightOmniMessageSystem(message, {
        isGroup,
        isMe,
        isFirstOfSender,
        sender
      });
      break;

    case MESSAGE_TYPE.TEXT:
      heightMessage = calculateHeightOmniMessageText(message, {
        isGroup,
        isMe,
        isFirstOfSender
      });

      break;

    case MESSAGE_TYPE.IMAGE:
      if (typeof message.fileId === 'number' || message.file) {
        heightMessage = calculateHeightOmniMessageImage(message);
      }

      break;

    case MESSAGE_TYPE.FILE:
      if (typeof message.fileId === 'number' || message.file) {
        heightMessage = MESSAGE_FILE_HEIGHT;
      }
      break;
  }

  /** Chiều cao của ngày gửi tin nhắn khác ngày nhau */
  heightMessage += !isOnSameDate ? MESSAGE_DATE_HEIGHT : 0;

  /** Khoảng cách giữa các tin nhắn của cùng người gửi hay khác người gửi */
  heightMessage += message.senderId !== nextMess?.senderId ? MESSAGE_SPACING_LARGE : MESSAGE_SPACING;

  return heightMessage;
};
