import { DrawerProps, TabsProps, Typography } from 'antd';
import { Drawer, TabButtons } from 'components/common';
import { internalAnnouncementsMessages } from 'messages';
import { useGetInternalAnnouncementDetailQuery } from 'services';
import InternalAnnouncementInfo from './InternalAnnouncementInfo';
import InternalAnnouncementStatistic from './InternalAnnouncementStatistic';
import { InternalAnnouncementDto } from 'types';
import InternalAnnouncementActions from './InternalAnnouncementActions';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

export type InternalAnnouncementDetailProps = DrawerProps & {
  internalAnnouncementId?: number;
  onUpdateInfo?: (internalAnnouncement: InternalAnnouncementDto) => void;
  onUpdateStatus?: (internalAnnouncement: InternalAnnouncementDto) => void;
  onDelete?: (internalAnnouncement: InternalAnnouncementDto) => void;
};

const InternalAnnouncementDetailDrawer = ({
  internalAnnouncementId,
  onDelete,
  onUpdateStatus,
  onUpdateInfo,
  ...props
}: InternalAnnouncementDetailProps) => {
  const { data: internalAnnouncement, isLoading: isLoadingDetail } = useGetInternalAnnouncementDetailQuery(
    internalAnnouncementId!,
    {
      skip: !internalAnnouncementId,
      refetchOnMountOrArgChange: true
    }
  );
  const [selectedTab, setSelectedTab] = useState('1');

  const data = internalAnnouncement?.data;

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: internalAnnouncementsMessages.info
    },
    {
      key: '2',
      label: internalAnnouncementsMessages.statistic
    }
  ];
  return (
    <Drawer
      {...props}
      loading={isLoadingDetail}
      width={680}
      extra={
        <div>
          {data && (
            <InternalAnnouncementActions
              internalAnnouncement={data}
              onUpdateInfo={onUpdateInfo}
              onDelete={onDelete}
              onUpdateStatus={onUpdateStatus}
            />
          )}
        </div>
      }
    >
      <div className='flex h-full flex-col'>
        <div className={twMerge('min-h-9 w-full', isLoadingDetail && !data && 'skeleton-active')}>
          <Typography.Title className='text-2.5xl'>{data?.title}</Typography.Title>
        </div>
        <TabButtons items={items} activeKey={selectedTab} onClick={setSelectedTab} />
        <div className={twMerge('mt-8 min-h-[124px] w-full', isLoadingDetail && !data && 'skeleton-active')}>
          {selectedTab === '1' && (
            <InternalAnnouncementInfo
              internalAnnouncementId={internalAnnouncementId}
              data={data}
              isLoading={isLoadingDetail}
            />
          )}
          {selectedTab === '2' && <InternalAnnouncementStatistic data={data} isLoading={isLoadingDetail} />}
        </div>
      </div>
    </Drawer>
  );
};
export default InternalAnnouncementDetailDrawer;
