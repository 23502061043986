import { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import PreviewFileModal from './PreviewFileModal';
import ServerFile from './ServerFile';
import { fileServerDownload } from 'utils';

type ServerFileListProps = {
  fileList: number[];
  className?: string;
  itemProps?: {
    className?: string;
    showInfo?: boolean;
  };
  isPreview?: boolean;
};
const ServerFileList: React.FC<ServerFileListProps> = ({ isPreview, fileList, className, itemProps }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number>();

  const handlePreview = (idx: number) => {
    setCurrentIndex(idx);
    setIsModalOpen(true);
  };
  return (
    <div className={twMerge('slim-scrollbar-horizontal mb-2 flex w-full items-center gap-2 overflow-auto', className)}>
      {fileList &&
        fileList?.map((file, idx) => (
          <ServerFile
            key={file}
            {...itemProps}
            preview={(data) => handlePreview(idx)}
            isPreview={isPreview}
            className={twMerge('h-24 w-auto', itemProps?.className)}
            fileId={file}
            download={(data) => {
              if (data.url) {
                fileServerDownload({
                  url: data.url,
                  originalName: data.name
                });
              }
            }}
          />
        ))}
      {isPreview && isModalOpen && (
        <PreviewFileModal
          currentIndex={currentIndex}
          open={isModalOpen}
          fileList={fileList}
          onCancel={() => {
            setIsModalOpen(false);
          }}
          isDownload
        />
      )}
    </div>
  );
};
export default ServerFileList;
