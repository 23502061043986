import { createApi } from '@reduxjs/toolkit/query/react';
import { ResponseDto, ResponsePagingDto } from 'types';
import { CustomerDto, ElectricalRecording, ElectricalRecordingSchedule, OTTInformation, ThirdPartyPaymentHistory, ThirdPartyPaymentInfor } from 'types/dto/customer-lookup';
import {
  CalculateConsumption,
  CustomerPeriods,
  Periods,
  PowerComsumption
} from 'types/dto/customer-lookup/electricity-consumption.dto';
import { FindAllCustomersDto } from 'types/dto/customer-lookup/find-all-customers.dto';
import {
  CustomerCodeCadastralCode,
  CustomerContactInfo,
  CustomerPriceContract,
  PostCustomerCode
} from 'types/dto/customer-lookup/price-contract.dto';

import axiosBaseQuery from 'utils/base-api';
export const customerLookupApi = createApi({
  reducerPath: 'customerLookupApi',
  tagTypes: ['customers', 'customer_detail', 'customer_period', 'power_consumption', 'electrical_recording'],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getCustomers: builder.query<ResponsePagingDto<CustomerDto>, FindAllCustomersDto>({
      query: ({ customerName, customerAddress, meterSerialNumber, customerCode, routeCode, meterPointCode }) => {
        const params = new URLSearchParams();
        if (customerName) params.append('customerName', customerName);
        if (customerAddress) params.append('customerAddress', customerAddress);
        if (meterSerialNumber) params.append('meterSerialNumber', meterSerialNumber);
        if (customerCode) params.append('customerCode', customerCode);
        if (routeCode) params.append('routeCode', routeCode);
        if (meterPointCode) params.append('meterPointCode', meterPointCode);
        return {
          url: `/customers?${params}&pageSize=10`,
          method: 'get'
        };
      },
      providesTags: (result) =>
        result && result.data.rows.length > 0
          ? result.data.rows.map(({ customerId }) => ({
            type: 'customers',
            id: customerId
          }))
          : ['customers']
    }),
    getCustomersByFullText: builder.query<ResponsePagingDto<CustomerDto>, FindAllCustomersDto>({
      query: (params) => ({
        url: `/customers/fulltext_search?keyword=${params}`,
        method: 'get',
        params
      }),
      providesTags: (result) =>
        result && result.data.rows.length > 0
          ? result.data.rows.map(({ customerId }) => ({
            type: 'customers',
            id: customerId
          }))
          : ['customers']
    }),
    getCustomerDetail: builder.query<ResponseDto<CustomerDto>, number>({
      query: (customerId) => ({ url: `/customers/${customerId}`, method: 'get' }),
      providesTags: (result) => (result ? [{ type: 'customer_detail', id: result.data.customerId }] : [])
    }),
    getCustomerPriceContract: builder.query<ResponseDto<CustomerPriceContract>, String>({
      query: (customerCode) => ({
        url: `/cmis_info_retrieval/customer_price_contract`,
        method: 'get',
        params: { customerCode }
      })
    }),
    getCustomerMeterReadingCode: builder.query<ResponseDto<CustomerPriceContract>, String>({
      query: (customerCode) => ({
        url: `/cmis_info_retrieval/customer_meter_reading_code`,
        method: 'get',
        params: { customerCode }
      })
    }),
    postPowerSupplyStatus: builder.mutation<ResponseDto<CustomerPriceContract>, CustomerCodeCadastralCode>({
      query: (cusCodeDetail) => ({
        url: `/oms_info_retrieval/power_supply_status`,
        method: 'post',
        data: cusCodeDetail
      })
    }),
    postPowerCutShedule: builder.mutation<ResponseDto<CustomerPriceContract>, CustomerCodeCadastralCode>({
      query: (cusCodeDetail) => ({
        url: `/oms_info_retrieval/power_cut_schedule`,
        method: 'post',
        data: cusCodeDetail
      })
    }),
    postCustomerContractInfo: builder.mutation<ResponseDto<CustomerContactInfo>, PostCustomerCode>({
      query: (cusCodeDetail) => ({
        url: `/cmis_info_retrieval/customer_contract_info`,
        method: 'post',
        data: cusCodeDetail
      })
    }),

    //Get Customer Periods
    getCustomerPeriod: builder.query<ResponseDto<Periods>, string>({
      query: (customerCode) => ({
        url: `/cmis_info_retrieval/customer_power_usage_periods`,
        method: 'get',
        params: { customerCode }
      }),
      providesTags: (result) =>
        result?.data?.length
          ? result.data.map((row) => ({
            type: 'customer_period',
            id: row.period
          }))
          : []
    }),

    // Get customer power consumption
    getCustomerPowerConsumption: builder.query<ResponseDto<PowerComsumption>, CustomerPeriods>({
      query: (customerPeriod) => ({
        url: `/cmis_info_retrieval/customer_power_consumption`,
        method: 'get',
        params: customerPeriod
      }),
      providesTags: (result) => (result?.data ? [{ type: 'power_consumption' }] : [])
    }),

    // Get Calculate Customer Consumption
    getCalculateConsumption: builder.query<ResponseDto<CalculateConsumption>, CustomerPeriods>({
      query: (customerPeriods) => ({
        url: `/cmis_info_retrieval/calculate_customer_consumption`,
        method: 'get',
        params: customerPeriods
      })
    }),

    // Third Party Payment
    getCustomerThirdPartyPayment: builder.query<ResponseDto<ThirdPartyPaymentInfor>, String>({
      query: (customerCode) => ({
        url: `/cmis_info_retrieval/customer_third_party_payment_info`,
        method: 'get',
        params: { customerCode }
      })
    }),

    getThirdPartyPaymentHistory: builder.query<ResponseDto<ThirdPartyPaymentHistory>, String>({
      query: (customerCode) => ({
        url: `/cmis_info_retrieval/customer_third_party_payment_history`,
        method: 'get',
        params: { customerCode },
      }),
    }),

    getCustomerOTTInformation: builder.query<ResponseDto<OTTInformation>, String>({
      query: (customerCode) => ({
        url: `/app_cskh_info_retrieval/customer`,
        method: 'get',
        params: { search: customerCode },
      }),
    }),

    getCustomerElectricalRecordingSchedule: builder.query<ResponsePagingDto<ElectricalRecording>, String>({
      query: (sessionNumber) => ({
        url: `/customer_support_information/electrical_recording_schedules`,
        method: 'get',
        params: { sessionNumber },
      }),
      providesTags: (result) =>
        result && result.data.rows.length > 0
          ? result.data.rows.map(({ sessionNumber }) => ({
            type: 'electrical_recording',
            sessionNumber: sessionNumber
          }))
          : ['electrical_recording']
    }),
  })
});

export const {
  useGetCustomersQuery,
  useGetCustomerDetailQuery,
  usePostPowerSupplyStatusMutation,
  usePostPowerCutSheduleMutation,
  usePostCustomerContractInfoMutation,
  useGetCustomerPeriodQuery,
  useGetCustomerPowerConsumptionQuery,
  useGetCustomerPriceContractQuery,
  useGetCustomerMeterReadingCodeQuery,
  useGetCustomerThirdPartyPaymentQuery,
  useGetThirdPartyPaymentHistoryQuery,
  useGetCalculateConsumptionQuery,
  useGetCustomerOTTInformationQuery,
  useGetCustomerElectricalRecordingScheduleQuery
} = customerLookupApi

