import { List as ListAntd, ListProps } from 'antd';
import { useEffect, useState } from 'react';
import { DEFAULT_PAGE_SIZE } from 'utils';
import { customizeRenderEmpty } from '../customizeRenderEmpty';
import SkeletonRows from '../SkeletonRows';

type Props<T = unknown> = ListProps<T> & {};

const List = <T,>({ loading, ...props }: Props<T>) => {
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    if (!loading) {
      setFirstRender(false);
    }
  }, [loading]);

  if (!loading && !props.dataSource?.length && !firstRender)
    return <div className='min-h-[calc(100vh-220px)] bg-white'>{customizeRenderEmpty()}</div>;
  return (
    <ListAntd
      {...props}
      loading={loading}
      locale={{
        emptyText: (
          <div className='-ml-4 w-[calc(100%+32px)]'>
            <SkeletonRows rowCount={DEFAULT_PAGE_SIZE} />
          </div>
        )
      }}
    />
  );
};
export default List;
