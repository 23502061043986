import { OmniChatBox } from 'components/common';
import { useOmniChatContext } from 'contexts';
import { FileUpload } from 'types';

type ChatBoxProps = {};

const OmniChatInput: React.FC<ChatBoxProps> = () => {
  const { chatSession, onSentMessage } = useOmniChatContext();

  const handleSubmit = async (content: string, files: FileUpload[]) => {
    onSentMessage(content, files);
  };

  return (
    <div className='bg-white transition-all'>
      <OmniChatBox chatSession={chatSession} onSend={handleSubmit} />
    </div>
  );
};

export default OmniChatInput;
