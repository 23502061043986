import { createApi } from '@reduxjs/toolkit/query/react';
import { debounce, findIndex, uniqBy } from 'lodash';
import { AppDispatch } from 'store';
import {
  ChatSessionDto,
  CompleteOmniChannelSessionDto,
  CreateOmniChannelMessageDto,
  FindAllOmniMessagesDto,
  GetOmniMessageResponseDto,
  OmniChannelMessageDto,
  ReadOmniChannelMessageDto,
  ResponseDto,
  ResponsePagingDto
} from 'types';
import { FindAllChatSessions, OmniChannelCallGroup, OmniChannelFanpageDto, OmniChannelGatewayConfigDto } from 'types';
import {
  CHAT_STATUS,
  convertMetadata,
  extractUrls,
  fetchMetadata,
  mergeArrays,
  MESSAGE_LIMIT_CURRENT,
  MESSAGE_LIMIT_LOAD,
  OMNI_MESSAGE_QUERY_TYPE
} from 'utils';
import axiosBaseQuery from 'utils/base-api';
import {
  offReceiveChatSessionProcessing,
  offReceiveChatSessionWaiting,
  offRemoveChatSessionWaiting,
  onReceiveChatSessionProcessing,
  onReceiveChatSessionWaiting,
  onRemoveChatSessionWaiting
} from './omni-channel.socket-client';

export const omniChannelApi = createApi({
  reducerPath: 'omniChannelApi',
  tagTypes: [
    'omni_channel_gateway_config',
    'omni_channel_group_calls',
    'omni_channel_fanpages',
    'omni_channel_chat_session_processing',
    'omni_channel_chat_session_waiting',
    'omni_channel_message'
  ],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getGroupCalls: builder.query<ResponseDto<OmniChannelCallGroup[]>, void>({
      query: () => ({
        url: '/omni_channel/get_group_call',
        method: 'get'
      }),
      providesTags: ['omni_channel_group_calls']
    }),
    getGatewayConfig: builder.query<ResponseDto<OmniChannelGatewayConfigDto>, void>({
      query: () => ({
        url: '/omni_channel/omni_gateway_config',
        method: 'get'
      }),
      providesTags: ['omni_channel_gateway_config']
    }),
    getFanpages: builder.query<ResponsePagingDto<OmniChannelFanpageDto>, void>({
      query: () => ({
        url: '/omni_channel/fanpage',
        method: 'get'
      }),
      providesTags: ['omni_channel_fanpages']
    }),

    getChatSessionsWaiting: builder.query<ResponsePagingDto<ChatSessionDto>, FindAllChatSessions>({
      query: () => ({
        url: '/omni_channel/chat_sessions/waiting',
        method: 'get'
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentCache, newItems, { arg }) => {
        if (arg.pageIndex !== 1) {
          currentCache.data.rows = mergeArrays(currentCache.data.rows, newItems.data.rows, 'chatSessionId');
        } else currentCache.data.rows = newItems.data.rows;
        currentCache.data.count = newItems.data.count;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.keyword !== previousArg?.keyword || currentArg?.pageIndex !== previousArg?.pageIndex;
      },
      providesTags: (result) =>
        result && result.data.rows.length > 0
          ? result.data.rows.map(({ chatSessionId }) => ({
              type: 'omni_channel_chat_session_waiting',
              id: chatSessionId
            }))
          : ['omni_channel_chat_session_waiting'],
      async onCacheEntryAdded(arg, { cacheDataLoaded, updateCachedData, cacheEntryRemoved }) {
        try {
          await cacheDataLoaded;

          onReceiveChatSessionWaiting((data) => {
            if (data && data.chatSessionId) {
              debounce(() => {
                updateCachedData((draft) => {
                  const chatSessionsWaiting = [...draft.data.rows, data].filter(
                    (chatSession) => chatSession.status === CHAT_STATUS.WAITING
                  );
                  let chatSessions = uniqBy(chatSessionsWaiting, 'chatSessionId');
                  draft.data.rows = chatSessions;
                });
              }, 100)();
            }
          });

          onRemoveChatSessionWaiting((data) => {
            if (data && data.chatSessionId) {
              debounce(() => {
                updateCachedData((draft) => {
                  const chatSessionsWaiting = [...draft.data.rows].filter(
                    (chatSession) => data.chatSessionId !== chatSession.chatSessionId
                  );
                  let chatSessions = uniqBy(chatSessionsWaiting, 'chatSessionId');
                  draft.data.rows = chatSessions;
                });
              }, 100)();
            }
          });
        } catch (err) {
          console.error({ err });
        }

        await cacheEntryRemoved;
        offReceiveChatSessionWaiting();
        offRemoveChatSessionWaiting();
      }
    }),

    getChatSessionsProcessing: builder.query<ResponsePagingDto<ChatSessionDto>, FindAllChatSessions>({
      query: () => ({
        url: '/omni_channel/chat_sessions/processing',
        method: 'get'
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentCache, newItems, { arg }) => {
        if (arg.pageIndex !== 1) {
          currentCache.data.rows = mergeArrays(currentCache.data.rows, newItems.data.rows, 'chatSessionId');
        } else currentCache.data.rows = newItems.data.rows;
        currentCache.data.count = newItems.data.count;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.keyword !== previousArg?.keyword || currentArg?.pageIndex !== previousArg?.pageIndex;
      },
      providesTags: (result) =>
        result && result.data.rows.length > 0
          ? result.data.rows.map(({ chatSessionId }) => ({
              type: 'omni_channel_chat_session_processing',
              id: chatSessionId
            }))
          : ['omni_channel_chat_session_processing'],
      async onCacheEntryAdded(arg, { cacheDataLoaded, updateCachedData, cacheEntryRemoved }) {
        try {
          await cacheDataLoaded;

          onReceiveChatSessionProcessing((data) => {
            if (data && data.chatSessionId) {
              debounce(() => {
                updateCachedData((draft) => {
                  let chatSessions = [...draft.data.rows];
                  const idxChatSessionUpdated = chatSessions.findIndex((o) => o.chatSessionId === data.chatSessionId);

                  if (idxChatSessionUpdated !== -1) {
                    if (data.status === CHAT_STATUS.COMPLETED) {
                      chatSessions.splice(idxChatSessionUpdated, 1);
                    } else {
                      chatSessions[idxChatSessionUpdated] = { ...data };
                    }
                  } else if (data.status !== CHAT_STATUS.COMPLETED) {
                    chatSessions.push(data);
                  }

                  draft.data.rows = Array.from(
                    new Map(chatSessions.map((session) => [session.chatSessionId, session])).values()
                  );
                });
              }, 100)();
            }
          });
        } catch (err) {
          console.error({ err });
        }

        await cacheEntryRemoved;
        offReceiveChatSessionProcessing();
      }
    }),

    getOmniChatMessages: builder.query<GetOmniMessageResponseDto, FindAllOmniMessagesDto>({
      query: ({ ...params }) => ({
        url: '/omni_channel/messages',
        method: 'get',
        params
      }),

      async transformResponse(response: GetOmniMessageResponseDto) {
        const updatedRows = await addMetadataToOmniMessages(response.data.rows);
        return {
          ...response,
          data: {
            ...response.data,
            rows: updatedRows.map((o) => ({
              ...o,
              senderId: isNaN(Number(o.senderId)) ? o.senderId : Number(o.senderId)
            }))
          }
        };
      },

      serializeQueryArgs: ({ endpointName, queryArgs }) => endpointName + queryArgs.chatId,
      merge: (currentCache, newItems, { arg }) => {
        if (arg?.startMessageId) {
          let currentRows = [...currentCache.data.rows];
          if (arg.sortOrder === OMNI_MESSAGE_QUERY_TYPE.NEWER) {
            currentRows =
              currentRows.length >= MESSAGE_LIMIT_CURRENT ? currentRows.slice(MESSAGE_LIMIT_LOAD) : currentRows;
            currentCache.data.rows = [...currentRows, ...newItems.data.rows];
          } else {
            currentRows =
              currentRows.length >= MESSAGE_LIMIT_CURRENT ? currentRows.slice(0, -MESSAGE_LIMIT_LOAD) : currentRows;
            currentCache.data.rows = [...newItems.data.rows, ...currentRows];
          }
          currentCache.data.count = newItems.data.count;
          currentCache.newMess = newItems.data.rows;
          return;
        }

        currentCache.data.count = newItems.data.count;
        currentCache.newMess = newItems.data.rows;
        currentCache.data.rows = newItems.data.rows;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.chatId !== previousArg?.chatId;
      },
      providesTags: (result) =>
        result && result.data.rows.length > 0
          ? result.data.rows.map(({ messageId, chatId }) => ({
              type: 'omni_channel_message',
              id: chatId
            }))
          : ['omni_channel_message']
    }),

    receiveChatSession: builder.mutation<ResponseDto<ChatSessionDto>, void>({
      query: () => ({
        url: '/omni_channel/receive_chat_session',
        method: 'post'
      }),
      invalidatesTags: ['omni_channel_chat_session_waiting', 'omni_channel_chat_session_processing']
    }),

    sendOmniChatMessage: builder.mutation<ResponseDto<OmniChannelMessageDto>, CreateOmniChannelMessageDto>({
      query: (newChat) => ({
        url: '/omni_channel/create_message',
        method: 'post',
        data: newChat
      }),
      transformResponse: (response: ResponseDto<OmniChannelMessageDto>) => {
        return {
          ...response,
          data: {
            ...response.data,
            senderId: isNaN(Number(response.data.senderId)) ? response.data.senderId : Number(response.data.senderId)
          }
        };
      }
    }),

    readOmniChatMessages: builder.mutation<ResponseDto<ChatSessionDto>, ReadOmniChannelMessageDto>({
      query: ({ chatSessionId, ...data }) => ({
        url: `/omni_channel/read_messages/${chatSessionId}`,
        method: 'post',
        data: data
      })
    }),

    completeChatSession: builder.mutation<ResponseDto<ChatSessionDto>, CompleteOmniChannelSessionDto>({
      query: ({ chatSessionId, ...data }) => ({
        url: `/omni_channel/complete_chat_session/${chatSessionId}`,
        method: 'post',
        data: data
      }),
      invalidatesTags: ['omni_channel_chat_session_waiting', 'omni_channel_chat_session_processing']
    })
  })
});

async function addMetadataToOmniMessages(rows: OmniChannelMessageDto[]) {
  return await Promise.all(
    rows.map(async (row) => {
      if (row.content && row.content.length > 0) {
        return await addMetadataOmniMessage(row);
      }
      return row;
    })
  );
}

async function addMetadataOmniMessage(message: OmniChannelMessageDto) {
  const urls = extractUrls(message.content ?? '');
  if (urls.length > 0) {
    const metadataTag = await fetchMetadata(urls[0]);
    if (metadataTag) {
      const metadata = convertMetadata(metadataTag);
      return { ...message, metadata };
    }
  }
  return message;
}

function addMessageOmniChat(data: OmniChannelMessageDto, endpointName: any, arg?: Partial<FindAllOmniMessagesDto>) {
  return (dispatch: AppDispatch) => {
    dispatch(
      omniChannelApi.util.updateQueryData(endpointName, arg, (draftData: any) => {
        let currentRows = [...draftData.data.rows];
        currentRows = currentRows.length >= MESSAGE_LIMIT_CURRENT ? currentRows.slice(MESSAGE_LIMIT_LOAD) : currentRows;

        currentRows.push(data);
        draftData.data.count += 1;
        draftData.data.rows = currentRows;
      })
    );
  };
}

function updateMessageOmniChat(
  data: OmniChannelMessageDto,
  tempMessageId: number,
  endpointName: any,
  arg?: Partial<FindAllOmniMessagesDto>
) {
  return (dispatch: AppDispatch) => {
    dispatch(
      omniChannelApi.util.updateQueryData(endpointName, arg, (draftData: any) => {
        const idx = findIndex(draftData.data.rows || [], {
          messageId: tempMessageId
        });
        if (idx !== -1) {
          draftData.data.rows[idx] = {
            ...data
          };
        }
      })
    );
  };
}

export { addMessageOmniChat, addMetadataOmniMessage, updateMessageOmniChat };
export const {
  useLazyGetChatSessionsProcessingQuery,
  useLazyGetChatSessionsWaitingQuery,
  useGetChatSessionsWaitingQuery,
  useGetChatSessionsProcessingQuery,
  useReceiveChatSessionMutation,
  useCompleteChatSessionMutation,
  useLazyGetOmniChatMessagesQuery,
  useReadOmniChatMessagesMutation,
  useSendOmniChatMessageMutation,
  useGetGroupCallsQuery,
  useGetFanpagesQuery,
  useGetGatewayConfigQuery
} = omniChannelApi;
