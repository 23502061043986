export const systemConfigsMessages = {
  ratingSystem: 'Hệ thống đánh giá',
  callCenterSystem: 'Hệ thống tổng đài',
  ratingSystemApiUrl: 'API URL',
  ratingSystemApiKey: 'API key',
  noValue: 'Chưa có',
  updateRatingSystem: 'Cập nhật hệ thống đánh giá',
  callCenterSystemHost: 'Host',
  callCenterSystemApiUrl: 'API URL',
  callCenterSystemDescription: 'Mô tả',
  callCenterSystemApiList: 'Danh sách API',
  connectCallCenterSystem: 'Kết nối hệ thống tổng đài',
  integrateFanPage: 'Tích hợp fanpage',
  fanPageApiUrl: 'API URL',
  fanPageDescription: 'Mô tả',
  gatewayApiName: 'Tên API',
  gatewayApiPath: 'Đường dẫn',
  gatewayApiDescription: 'Mô tả'
};
