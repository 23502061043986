import { List as ListAntd } from 'antd';
import OriginList from './List';
import { ListItemTypeProps } from 'antd/es/list/Item';

export type ListProps = typeof OriginList & {
  Item: ListItemTypeProps;
};
const List = OriginList as ListProps;

List.Item = ListAntd.Item;
export { List };
