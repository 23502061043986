import { createApi } from '@reduxjs/toolkit/query/react';
import { FindAllServiceTypeCMISDto, ResponsePagingDto, ServiceTypeCMISDto } from 'types';
import axiosBaseQuery from 'utils/base-api';

export const serviceTypeCMISApi = createApi({
  reducerPath: 'serviceTypeCMISApi',
  tagTypes: ['service_type_cmis'],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getServiceTypeCMIS: builder.query<ResponsePagingDto<ServiceTypeCMISDto>, FindAllServiceTypeCMISDto>({
      query: (params) => ({
        url: '/service_types_cmis',
        method: 'get',
        params
      }),
      providesTags: (result) =>
        result && result.data.rows.length > 0
          ? result.data.rows.map(({ serviceTypeCmisId }) => ({
              type: 'service_type_cmis',
              id: serviceTypeCmisId
            }))
          : ['service_type_cmis']
    })
  })
});

export const { useGetServiceTypeCMISQuery } = serviceTypeCMISApi;
