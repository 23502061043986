import { messages } from 'messages';
import { CHAT_STATUS } from 'utils';

export const useChatStatusLabel = (status: CHAT_STATUS) => {
  switch (status) {
    case CHAT_STATUS.COMPLETED:
      return messages.completed;
    case CHAT_STATUS.PROCESSING:
      return messages.processing;
    case CHAT_STATUS.WAITING:
      return messages.waiting;
  }
};
