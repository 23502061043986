export enum OMNI_CHANNEL_EVENT {
  START = 'START',
  DIALING = 'DIALING',
  DIALANSWER = 'DIALANSWER',
  HANGUP = 'HANGUP',
  CDR = 'CDR',
  TRIM = 'TRIM',
  UPDATE_TOKEN = 'update_token',
  TOKEN_EXPIRED = 'token_expired',
  UNAUTHORIZED = 'unauthorized',
  JOIN_CHAT_SESSION = 'JOIN_CHAT_SESSION',
  LEAVE_CHAT_SESSION = 'LEAVE_CHAT_SESSION',
  ADD_CHAT_SESSION_WAITING = 'ADD_CHAT_SESSION_WAITING',
  REMOVE_CHAT_SESSION_WAITING = 'REMOVE_CHAT_SESSION_WAITING',
  CHAT_SESSION_PROCESSING = 'CHAT_SESSION_PROCESSING',
  MESSAGE = 'MESSAGE',

  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  CONNECT_ERROR = 'connect_error',
  ERROR = 'error',
  EXCEPTION = 'exception'
}
