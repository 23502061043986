import { MenuProps, Typography } from 'antd';
import { DownloadFileIcon } from 'assets';
import { File } from 'components/common';
import dayjs from 'dayjs';
import { internalChatMessages } from 'messages';
import { memo, useEffect, useRef, useState } from 'react';
import { FileDto, OmniChannelMessageDto } from 'types';
import { fileServerDownload, loadFileFromUrl, MESSAGE_FILE_HEIGHT, TIME_FORMAT } from 'utils';
import MessageOptions, { MessageOptionsRef } from './MessageOptions';
import { twMerge } from 'tailwind-merge';
import { useProfile } from 'hooks';
import OtherFile from '../files/OtherFile';

type MessageFileProps = {
  fileId?: number;
  file?: string;
  onForwardMessage?: (message: OmniChannelMessageDto) => void;
  data: OmniChannelMessageDto;
  showReply?: boolean;
  isList?: boolean;
};

const OmniMessageFile: React.FC<MessageFileProps> = ({
  onForwardMessage,
  data,
  fileId,
  file,
  showReply = true,
  isList
}) => {
  const { profile } = useProfile();

  const [fileLoaded, setFileLoaded] = useState<Partial<FileDto>>();
  const optionsRef = useRef<MessageOptionsRef>(null);

  const isMe = typeof data.senderId === 'number' && data.senderId === profile?.userId;

  const items: MenuProps['items'] = [
    {
      key: 'download',
      label: internalChatMessages.download,
      onClick: () => {
        if (fileLoaded?.url) {
          fileServerDownload({
            url: fileLoaded.url,
            objectName: fileLoaded.objectName,
            originalName: fileLoaded.objectName
          });
        }
      },
      icon: <DownloadFileIcon />
    }
  ];

  useEffect(() => {
    if (!fileId && file) {
      loadDataFromUrl();
    }
  }, [file]);

  const loadDataFromUrl = async () => {
    if (!file) return;
    const dataFile = await loadFileFromUrl(file);
    if (dataFile)
      setFileLoaded({
        url: file,
        objectName: dataFile.name,
        fileSize: dataFile.size,
        fileType: dataFile.type,
        originalName: dataFile.name
      });
  };

  return (
    <div className='relative'>
      <div
        onContextMenu={(e) => {
          optionsRef.current?.open(e);
        }}
        className={twMerge(
          'relative w-auto min-w-[140px] max-w-[320px] cursor-pointer rounded-lg px-3',
          isMe ? 'bg-colorBgMyMessage' : 'bg-colorBgMessage'
        )}
      >
        <div
          onClick={() => {
            if (fileLoaded?.url) {
              fileServerDownload({
                url: fileLoaded.url,
                objectName: fileLoaded.objectName,
                originalName: fileLoaded.objectName
              });
            }
          }}
          className='flex items-center'
          style={{
            height: MESSAGE_FILE_HEIGHT
          }}
        >
          {fileId ? (
            <File.Server
              onDataFetched={(file) => {
                setFileLoaded(file);
              }}
              fileId={Number(fileId)}
              iconSize={42}
              className='h-full w-full border-0 p-0'
            />
          ) : file ? (
            <OtherFile
              name={fileLoaded?.originalName || fileLoaded?.objectName}
              fileSize={fileLoaded?.fileSize}
              type={fileLoaded?.fileType}
              iconSize={42}
              className='h-full w-full border-0 p-0'
            ></OtherFile>
          ) : null}
        </div>
        {!isList && (
          <div className='absolute bottom-1 left-1 rounded-2xl bg-textHoverBg px-1'>
            <Typography.Paragraph className='mb-0 text-xs' type='secondary'>
              {dayjs(data.createdAt).format(TIME_FORMAT)}
            </Typography.Paragraph>
          </div>
        )}
      </div>
      <MessageOptions onForwardMessage={onForwardMessage} ref={optionsRef} items={items} message={data} />
    </div>
  );
};

export default memo(OmniMessageFile);
