import { debounce } from "lodash";
import { useCallback, useState } from "react";
import { useGetWardsByDistrictIdQuery } from "services/administrative-units";
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from "utils";

const pageSize = DEFAULT_PAGE_SIZE;
export function useWardsByDistrictId(districtId: number) {
    const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX)
    const [keyword, setKeyword] = useState('');
    const {
        data: wardsResponse,
        isLoading,
    } = useGetWardsByDistrictIdQuery({
        keyword,
        pageIndex: pageIndex,
        pageSize,
        districtId
    },
    {
        skip: !districtId,
        refetchOnMountOrArgChange: true
    });

    const { rows, count } = wardsResponse?.data || {};
    
    const handleLoadMore = () => {
        if (rows && count && rows.length < count) {
        setPageIndex((prev) => prev + 1);
        }
    };

    const handleSearch = (value: string) => {
        debouncedKeywordChange(value);
    };

    const debouncedKeywordChange = useCallback(
        debounce((keywordValue: string) => {
          setKeyword(keywordValue);
          setPageIndex(1);
        }, 500),
        [setKeyword]
    );

    return {
        wards: rows || [],
        count: count || 0,
        isLoading,
        handleLoadMore,
        handleSearch,
        hasMore: rows && count ? rows.length < count : false
    }
}