import { CheckPermissionLayout } from 'layouts';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { PERMISSION } from 'utils';
import { ROUTE } from './constants';

export const accessCustomerLookupRoutes: RouteObject[] = [
  {
    element: <CheckPermissionLayout permission={[PERMISSION.ACCESS_CUSTOMER_LOOKUP]} />,
    children: [
      {
        path: ROUTE.CUSTOMER_LOOKUP,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/CustomerLookup'))
          },
          {
            path: ':customerId/:customerCode',
            Component: lazy(() => import('pages/CustomerLookupDetailed'))
          }
        ]
      }
    ]
  }
];
