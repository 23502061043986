import { Loadable } from 'components/common';
import { lazy } from 'react';
import { InView } from 'react-intersection-observer';
import { useOmniChatContext } from 'contexts';
import { Button, Spin } from 'antd';
import { ArrowDownIcon } from 'assets';
const OmniMessageList = lazy(() => import('./OmniMessageList'));

type OmniChatMessageBoxProps = {};

const OmniChatMessageBox: React.FC<OmniChatMessageBoxProps> = () => {
  const {
    positions,
    messagesBoxRef,
    messageListRef,
    messages,
    hasMore,
    hasNew,
    onGetOlderMess,
    onGetNewerMess,
    isAtBottomScroll,
    scrollToEndMessage,
    isFetching,
    isLoading
  } = useOmniChatContext();

  return (
    <div className='relative flex-grow overflow-hidden'>
      <Spin
        prefixCls='spin-omni-chat'
        delay={3000}
        spinning={isFetching || isLoading}
        wrapperClassName='max-h-full h-full'
        className='h-full'
      >
        <div
          ref={messagesBoxRef}
          className='fade-scrollbar h-full'
          style={{
            overflowY: 'auto'
          }}
        >
          <div ref={messageListRef} className='relative min-h-full w-full overflow-hidden px-5'>
            {hasMore ? (
              <InView
                className='absolute h-10 w-full'
                style={{
                  top: positions[5]?.position,
                  height: positions[5]?.height
                }}
                as='div'
                onChange={(inView) => {
                  if (inView) {
                    onGetOlderMess();
                  }
                }}
              />
            ) : null}

            {hasNew ? (
              <InView
                className='absolute h-10 w-full'
                as='div'
                style={{
                  top: positions[positions.length - 5]?.position,
                  height: positions[positions.length - 5]?.height
                }}
                onChange={(inView) => {
                  if (inView) {
                    onGetNewerMess();
                  }
                }}
              />
            ) : null}
            <Loadable>
              <OmniMessageList messages={messages} />
            </Loadable>
          </div>
        </div>
        {!isAtBottomScroll && (
          <div className='absolute bottom-4 left-1/2 z-50 -translate-x-1/2'>
            <Button onClick={scrollToEndMessage} className='h-8 w-8' shape='circle' icon={<ArrowDownIcon />}></Button>
          </div>
        )}
      </Spin>
    </div>
  );
};

export default OmniChatMessageBox;
