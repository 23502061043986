import { usePaging } from 'hooks/usePaging';
import { useCallback } from 'react';
import { useGetPermissionsUsersQuery } from 'services';
import { FindAllPermissionUsersDto } from 'types';
import { DEFAULT_PAGE_SIZE } from 'utils';
const pageSize = DEFAULT_PAGE_SIZE;
export function usePermissionsUsers(params: Omit<FindAllPermissionUsersDto, 'pageIndex' | 'pageSize'>) {
  const { pageIndex, resetPage, nextPage } = usePaging(params);
  const {
    data: dataResponse,
    isLoading,
    isFetching
  } = useGetPermissionsUsersQuery({
    pageIndex: pageIndex,
    pageSize,
    ...params
  });

  const { rows, count } = dataResponse?.data || {};

  const hasMore = Boolean(rows && count && rows.length < count);
  const handleLoadMore = useCallback(() => {
    if (hasMore && !isFetching) {
      nextPage();
    }
  }, [hasMore, pageIndex]);

  return {
    users: rows || [],
    count: count || 0,
    isFetching,
    isLoading,
    handleLoadMore,
    hasMore,
    resetPage
  };
}
