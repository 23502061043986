import { CheckPermissionLayout } from 'layouts';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { PERMISSION } from 'utils';
import { ROUTE } from './constants';

export const accessManagementRoutes: RouteObject[] = [
  {
    element: <CheckPermissionLayout permission={[PERMISSION.ACCESS_MANAGEMENT]} />,
    children: [
      {
        path: ROUTE.CUSTOMER_SUPPORT_INFORMATION,
        element: <CheckPermissionLayout permission={[PERMISSION.READ_CUSTOMER_SUPPORT_INFORMATION]} />,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/CustomerSupportInformationList'))
          },
          {
            path: ':customerSupportInformationId',
            Component: lazy(() => import('pages/CustomerSupportInformation'))
          }
        ]
      },
      {
        path: ROUTE.FREQUENTLY_ASKED_QUESTION,
        element: <CheckPermissionLayout permission={[PERMISSION.READ_CUSTOMER_SUPPORT_INFORMATION]} />,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/FrequentlyAskedQuestions'))
          }
        ]
      },
      {
        path: ROUTE.SERVICE_INSTRUCTIONS,
        element: <CheckPermissionLayout permission={[PERMISSION.READ_SERVICE_INSTRUCTION]} />,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/ServiceInstructions'))
          }
        ]
      },
      {
        path: ROUTE.WORKING_SCHEDULES,
        element: <CheckPermissionLayout permission={[PERMISSION.READ_WORKING_SCHEDULE]} />,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/WorkingSchedules'))
          }
        ]
      },
      {
        path: ROUTE.SHIFTS,
        element: <CheckPermissionLayout permission={[PERMISSION.READ_SHIFT]} />,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/Shifts'))
          }
        ]
      },
      {
        path: ROUTE.REASONS,
        element: <CheckPermissionLayout permission={[PERMISSION.READ_REASON]} />,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/Reasons'))
          }
        ]
      },
      {
        path: ROUTE.VIP_CUSTOMERS,
        element: <CheckPermissionLayout permission={[PERMISSION.READ_VIP_CUSTOMER]} />,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/VipCustomers'))
          }
        ]
      },
      {
        path: ROUTE.TICKET_CANCEL_REASONS,
        element: <CheckPermissionLayout permission={[PERMISSION.READ_TICKET_CANCEL_REASON]} />,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/TicketCancelReasons'))
          }
        ]
      },
      {
        path: ROUTE.INTERNAL_ANNOUNCEMENTS_DELETED,
        element: <CheckPermissionLayout permission={[PERMISSION.READ_INTERNAL_ANNOUNCEMENT]} />,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/InternalAnnouncementsDeleted'))
          }
        ]
      },
      {
        path: ROUTE.INTERNAL_ANNOUNCEMENTS,
        element: <CheckPermissionLayout permission={[PERMISSION.READ_INTERNAL_ANNOUNCEMENT]} />,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/InternalAnnouncements'))
          },
          {
            path: ':internalAnnouncementId',
            Component: lazy(() => import('pages/InternalAnnouncement'))
          }
        ]
      },
      {
        path: ROUTE.AGENT_MAPS,
        element: <CheckPermissionLayout permission={[PERMISSION.READ_AGENT_MAP]} />,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/AgentMaps'))
          },
          {
            path: ':agentMapId',
            Component: lazy(() => import('pages/AgentMap'))
          }
        ]
      },
      {
        path: ROUTE.FANPAGE_CONNECTIONS,
        Component: lazy(() => import('pages/FanpageConnections'))
      },
      {
        path: ROUTE.CALL_CENTER_SYSTEM,
        Component: lazy(() => import('pages/CallCenterSystem'))
      },
      {
        path: ROUTE.ORGANIZATION_UNIT_TICKET_DEFAULT_ASSIGNS,
        element: <CheckPermissionLayout permission={[PERMISSION.CONFIG_ORGANIZATION_UNIT_DEFAULT_HANDLE_TICKET]} />,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/OrganizationUnitTicketDefaultAssigns'))
          }
        ]
      }
    ]
  }
];
