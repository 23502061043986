import { checkPermissions } from 'utils/check-permission';
import { useProfile } from './auth';

export const useEveryPermission = (permissions?: number[]) => {
  const { userPermissionIds } = useProfile();
  const isEveryPermissions = permissions?.every((permission) => checkPermissions(userPermissionIds, permission));
  return {
    isEveryPermissions
  };
};
