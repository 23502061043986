import { Rule } from 'antd/es/form';
import { ApiSharingPartnerFormType } from 'components/api-sharing-partners/ApiSharingPartnerForm';

export const apiSharingPartnerValidationRules: Record<keyof ApiSharingPartnerFormType, Rule[]> = {
  name: [{ required: true }],
  description: [],
  code: [{ required: true }],
  apiKey: [{ required: true }, { max: 50 }],
  permissions: [],
  isActive: []
};
